<ac-modal-header class="container-header" (close)="close()">
  <h2 class="title">Manage Version</h2>
</ac-modal-header>

<ac-modal-body class="container-body">
  <button (click)="create()" type="button" [disabled]="isCreationDisabled" class="bnt-create">Create Version</button>

  <div class="container-manage-version">
    <div class="container-form-header">
      <div class="name">Version Name</div>
      <div class="date">Start Date</div>
      <div class="date end-date-column">End Date</div>
      <div class="action">Actions</div>
    </div>

    <form class="container-form-body" [formGroup]="form">
      <ng-container formArrayName="versions">
        <ng-container *ngFor="let versionFrom of form.get('versions')['controls']; first as isFirst; last as isLast; let i = index">
          <div class="group" [formGroup]="versionFrom">
            <div class="name">
              <ac-input>
                <input formControlName="versionName" placeholder="Version Name">
              </ac-input>
            </div>
            <div class="date">
              <ac-input>
                <ac-icon *ngIf="!isFirst" acPrefix icon="calendar" [width]="23" [height]="18" [active]="true"></ac-icon>
                <!-- show no active/disabled input in case of first version -->
                <input
                  *ngIf="isFirst"
                  readonly
                  class="start-date no-active"
                  placeholder="Start Date"
                  [value]="versions[i].value.startDate | date:'MM/dd/yyyy'"
                />
                <!-- show active input/datepicker in case of not first version -->
                <input
                  *ngIf="!isFirst"
                  readonly
                  #datePicker
                  bsDatepicker
                  class="start-date"
                  placement="bottom"
                  placeholder="Start Date"
                  [isDisabled]="isFirst || !versions[i - 1].value.startDate"
                  [bsConfig]="datePickerConfig"
                  (bsValueChange)="changeStartDate($event, i)"
                  [minDate]="i > 0 ? (versions[i - 1].value.startDate | convertToDate: true) : null"
                  [maxDate]="versions[i + 1] ? versions[i + 1].value.startDate : null"
                  formControlName="startDate"/>
              </ac-input>
              <button type="button" class="btn-reset" *ngIf="isLast && !isFirst && versions[i].value.startDate" (click)="reset(i)">
                Reset
              </button>
            </div>
            <div class="date end-date-column">
              <div class="end-date no-active end-date-label">
                <span>{{ versions[i + 1] && versions[i + 1].value.startDate ? (versions[i + 1].value.startDate  | date:'MM/dd/yyyy') : '-' }}</span>
              </div>
            </div>
            <div class="action">
              <button *ngIf="!isFirst" type="button" class="btn-delete fa fa-lg fa-trash" (click)="showConfirmModal(i)"></button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </form>
  </div>
</ac-modal-body>

<ac-modal-footer class="container-footer">
  <ac-button (click)="close()" class="secondary bnt-cancel" size="sm">Cancel</ac-button>
  <ac-button (click)="save()" [disabled]="form.invalid || !form.dirty" size="sm">Save</ac-button>
</ac-modal-footer>


<div class="container-confirm" *ngIf="isConfirmVisible">
  <div class="content">
    <div class="confirm-header">
      <h2 class="title">Confirm</h2>
      <button type="button" class="btn-confirm-close" (click)="cancel()">
        <span class="btn-text">esc</span>
        <ac-icon class="btn-icon" icon="close" [width]="21" [height]="21" [active]="false"></ac-icon>
      </button>
    </div>
    <div class="confirm-body">
      You are going to remove {{ versionNameToRemove }}. Continue?
    </div>
    <div class="confirm-footer">
      <ac-button (click)="remove()" class="bnt-yes" size="sm">Yes</ac-button>
      <ac-button (click)="cancel()" class="secondary" size="sm">Cancel</ac-button>
    </div>
  </div>
</div>
