import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';

import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { filter, first, switchMap } from 'rxjs/operators';

import { AppState } from '../../../../../reducers';
import {
  changeFormByChartType,
  configurationForm,
  configurationFormWithMetricQuestion,
  dynamicFormValue,
} from '../../../redux';
import { PlanConfigData } from '@core/model';
import { CHART_CONFIGURATION_STATE, CHART_STYLE_CONFIGURATION_STATE } from '../../../constants';
import { ConfigurationData } from '../../../models';

@Component({
  selector: 'ep-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationComponent implements OnInit {
  @Input() configurationStateId: string;
  @Input() id: string;
  @Input() plansConfigData: PlanConfigData[];

  questionsData$: Observable<{ data: any }>;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.questionsData$ = this.getConfigurationForm().pipe(
      switchMap(data => this.modifyChartConfiguration(data)),
      switchMap(data => this.modifyChartStyleConfiguration(data)),
      first()
    );
  }

  private getConfigurationForm(): Observable<ConfigurationData> {
    return this.store.pipe(
      select(
        configurationForm({
          id: this.id,
          stateId: this.configurationStateId,
        })
      ),
      filter(item => !!item)
    );
  }

  private modifyChartConfiguration(data: ConfigurationData): Observable<ConfigurationData> {
    if (this.configurationStateId === CHART_CONFIGURATION_STATE.id) {
      return this.store.select(
        configurationFormWithMetricQuestion({
          data,
          plansConfigData: this.plansConfigData,
        })
      );
    }

    return of(data);
  }

  private modifyChartStyleConfiguration(data: ConfigurationData): Observable<ConfigurationData> {
    if (this.configurationStateId === CHART_STYLE_CONFIGURATION_STATE.id) {
      return this.modifyBasedOnDynamicFormValue(data);
    } else {
      return of(data);
    }
  }

  private modifyBasedOnDynamicFormValue(data: ConfigurationData): Observable<ConfigurationData> {
    return this.store.select(dynamicFormValue).pipe(
      first(),
      switchMap((dataSourceValue: any) =>
        this.store.select(changeFormByChartType(data, dataSourceValue.chartDataSource))
      )
    );
  }
}
