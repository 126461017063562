import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'ensight-custom-page-pdf-generation',
  templateUrl: './custom-page-pdf-generation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPagePdfGenerationComponent {
  public pdfIsRendered = false;

  constructor(private cdr: ChangeDetectorRef) {}

  public visualizationReadyEvent(): void {
    setTimeout(() => {
      this.pdfIsRendered = true;
      this.cdr.detectChanges();
    }, 100);
  }
}
