import { Injectable } from '@angular/core';

import { first } from 'rxjs/operators';

import { ModalService, ModalRef } from '@assurance/bootstrap';

import { MODAL_DIMENSIONS } from '../constants';
import { ModalData, ModalDimensions, ModalType } from '@shared/models';

@Injectable()
export class ModalExecutor {
  constructor(private modal: ModalService) {}

  execute(modal: ModalType, data: ModalData, dimensions?: ModalDimensions) {
    return this.executeModal(modal, data, dimensions).afterClosed.pipe(first());
  }

  private executeModal(modal: ModalType, data: ModalData, dimensions?: ModalDimensions): ModalRef {
    const defaultDimensions = dimensions || MODAL_DIMENSIONS.get(modal) || MODAL_DIMENSIONS.get('default');

    return this.modal.open(modal, {
      data,
      width: defaultDimensions.width,
      height: defaultDimensions.height,
      maxWidth: defaultDimensions.maxWidth,
    });
  }
}
