import { Injectable } from '@angular/core';

import { ModalRef, ModalService } from '@assurance/bootstrap';

import { SalesStoriesInputsComponent } from '../../../modals/sales-stories-inputs/sales-stories-inputs.component';
import { SalesConceptModalData } from '../../models';
import { SelectVersionModalData } from '../../../modals/select-version-modal/select-version-modal.model';
import { SelectVersionModalComponent } from '../../../modals/select-version-modal/select-version-modal.component';

@Injectable()
export class ModalProviderService {
  constructor(private modal: ModalService) {}

  openSalesConceptModal(data: SalesConceptModalData): ModalRef {
    return this.modal.open(SalesStoriesInputsComponent, {
      data,
      width: '960px',
      height: '652px',
      disableEscapeEvent: true,
    });
  }

  openSelectedVersionModal(data: SelectVersionModalData): ModalRef {
    return this.modal.open(SelectVersionModalComponent, { data, minWidth: '436px', maxHeight: 'min-content' });
  }
}
