import { NgModule } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

import { FormatTextPipe } from './formatText.pipe';
import { CurrencyPlanPipe } from './currencyPlan.pipe';
import { KeysPipe } from './forKeys.pipe';
import { IfEmpty } from './ifEmpty.pipe';
import { OrderObjectBy } from './orderObjectBy.pipe';
import { SortBy } from './sortBy.pipe';
import { PlanPersentage } from './percentage.pipe';
import { PlanCurrency } from './planCurrency.pipe';
import { SpreadsheetValuePipe } from './spreadsheetValue.pipe';
import { SafeResourcePipe } from './safe-resource.pipe';
import { InsertContentDirective } from './insert-content.pipe';
import { DefaultPipe } from './default/default.pipe';

const pipes = [
  FormatTextPipe,
  CurrencyPlanPipe,
  KeysPipe,
  IfEmpty,
  OrderObjectBy,
  PlanPersentage,
  PlanCurrency,
  SpreadsheetValuePipe,
  SafeResourcePipe,
  InsertContentDirective,
  SortBy,
  DefaultPipe,
];

@NgModule({
  declarations: pipes,
  exports: pipes,
  providers: [CurrencyPipe],
})
export class AssurencePipesModule {}
