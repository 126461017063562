<div *ngIf="!salesConcepts || showSpinner" class="spinnerHolder">
  <ac-loader></ac-loader>
</div>

<ac-modal-header (close)="onCancel()">
  <h2 class="title">{{ views[selectedView].title }}</h2>
</ac-modal-header>

<ac-modal-body
  [ngClass]="{ 'settings-view': selectedView === 2 }"
  [ngSwitch]="selectedView"
>
  <ng-container *ngSwitchCase="0">
    <section
      class="sidebar-container"
      [ngClass]="{ withTemplates: salesConcepts && salesConcepts.length }"
    >
      <aside *ngIf="salesConcepts && salesConcepts.length" class="sales-concepts-modal">
        <ul>
          <li
            *ngFor="let page of salesConcepts; let i = index"
            title="{{ page.salesConceptLabel }}{{
              formErrors.sidebarPage(
                i,
                page.productsRange[0],
                page.carrierUiIds
              )
            }}"
            [class.disabled]="isPageDisabled(page) || !isProductTypeValid(page)"
            [class.selected]="isActivePage(page.config.uiId)"
            (click)="choosePage(page)"
          >
            <h5 class="item-title">{{ page.salesConceptLabel }}</h5>
          </li>
        </ul>
      </aside>
      <div
        *ngIf="
          activePage && !activePage?.description && !activePage?.previewFilePath
        "
        class="template-block"
      >
        <div class="preview-block">
          <p class="preview-message">The preview is not available</p>
        </div>
      </div>
      <div
        *ngIf="activePage?.description || activePage?.previewFilePath"
        class="preview-block"
        [class.error]="!activePageAvailable"
      >
        <p *ngIf="activePage?.description">{{ activePage?.description }}</p>
        <figure
          class="preview-image-container"
          *ngIf="activePage?.previewFilePath"
        >
          <div *ngIf="previewImageLoading" class="spinnerHolder">
            <ac-loader></ac-loader>
          </div>
          <img
            class="preview-image"
            [src]="activePage?.previewFilePath"
            alt="preview"
            (load)="onPreviewImageLoad()"
            [hidden]="previewImageLoading"
          />
        </figure>
      </div>
    </section>
  </ng-container>
  <ng-container *ngSwitchCase="1">
    <form
      class="body-container"
      [formGroup]="salesConceptForm"
      (ngSubmit)="views[selectedView].onSubmit()"
    >
      <div class="name-field-container">
        <span class="label required">Sales Story Name:</span>
        <input
          title="{{ formErrors.errorMessage }}"
          formControlName="title"
          type="text"
          class="form-control"
          placeholder="Sales story name"
        />
      </div>
      <section class="sidebar-container">
        <div class="plans-container">
          <div class="plans-item header">
            <h5>PRODUCTS ({{ plans.length }})</h5>
            <div>{{ productRange }}</div>
          </div>
          <ul formArrayName="products">
            <li
              class="plans-item"
              *ngFor="let plan of formControls.products.controls; let i = index"
              title="{{ plans[i].errorMessage }}"
            >
              <div
                *ngIf="showRadioButtons(); else checkBoxBlock"
                class="radio-btn align-content-center"
              >
                <se-radio-button
                  [disabled]="isProductDisabled(i)"
                  [formControlName]="i"
                  [checked]="plan.value"
                  [value]="plan.value"
                  (onSelect)="handleRadioButtonEvent(i)"
                ></se-radio-button>
              </div>
              <ng-template #checkBoxBlock>
                <div class="checkbox">
                  <se-checkbox
                    [disabled]="isProductDisabled(i)"
                    [size]="'large'"
                    [checked]="plan.value"
                    [formControlName]="i"
                    [customIcon]="findById(plans[i].id)"
                    (onToggle)="checkboxEvent(i)"
                  ></se-checkbox>
                </div>
              </ng-template>
              <div class="content">
                <h5>{{ plans[i].productName }}</h5>
                <div class="mb-2">{{ plans[i].label }}</div>
                <ensight-note-input
                  class="note-field"
                  [note]="plans[i].productNote"
                  (changedNote)="onSaveNote(plans[i], $event)"
                  (keydown.escape)="$event.stopPropagation()"
              ></ensight-note-input>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </form>
  </ng-container>

  <ng-container *ngSwitchCase="2">
    <form
      class="settings-form"
      [formGroup]="settingsForm"
      (ngSubmit)="views[selectedView].onSubmit()"
    >
      <div class="control-container" *ngFor="let insert of inserts">
        <label class="label">{{ insert.metadata.placeholderName }}</label>
        <se-form-input
          class="form-input"
          [formControl]="settingsForm.controls[insert.config.uiId]"
          [inputPattern]="inputPattern"
        ></se-form-input>
        <span
          class="error-message"
          *ngFor="
            let message of getErrorMessages(
              settingsForm.controls[insert.config.uiId].errors,
              insert
            )
          "
        >
          {{ message }}
        </span>
      </div>
    </form>
  </ng-container>
</ac-modal-body>

<ac-modal-footer>
  <ac-button (click)="onCancel()" class="secondary" size="sm">Cancel</ac-button>
  <ac-button
    *ngIf="isBackBtnShown()"
    (click)="onBack()"
    class="secondary"
    size="sm"
  >
    Back
  </ac-button>
  <ac-button
    [disabled]="!formAvailable"
    (click)="views[selectedView].onSubmit()"
    type="submit"
    size="sm"
  >
    {{ getButtonLabel(selectedView) }}
  </ac-button>
</ac-modal-footer>
