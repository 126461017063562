import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { CustomPageInsert, CustomPagesConfig, CustomPageValues, InsertConfig } from '@shared/models';
import { INSERT_TYPE } from '@core/enums';
import { APIService, Global } from '@shared/services';
import { AppState } from '../../../../../reducers';
import { CustomPageService } from '../../../setup/custom-page/custom-page.service';
import { MappedSalesConcepts, SalesConcept } from '../../models';
import { getSalesConcepts, getDependentPagesConfig } from '../../../setup/setup.selectors';
import { presentationDataLoadSuccess } from '../../../presentation.actions';

@Injectable()
export class SalesStoryInputsService {
  private concepts: SalesConcept[];
  private pagesConfig: CustomPagesConfig[];

  private get isConcepts(): boolean {
    return this.getSalesConceptFormDirty().length > 0;
  }

  constructor(
    private store: Store<AppState>,
    private fb: FormBuilder,
    private customPageService: CustomPageService,
    private apiService: APIService,
    private global: Global
  ) {}

  getSalesConcepts(): Observable<SalesConcept[]> {
    return combineLatest([this.store.select(getSalesConcepts), this.getDependentPagesConfig()]).pipe(
      map((data: [CustomPageValues[], CustomPagesConfig[]]) => {
        const [concepts, configs] = data;

        return { concepts, configs };
      }),
      filter((data: MappedSalesConcepts) => data.concepts.length > 0),
      tap((data: MappedSalesConcepts) => (this.pagesConfig = data.configs)),
      map((data: MappedSalesConcepts) => this.getMappedSalesConcepts(data)),
      tap((concepts: SalesConcept[]) => (this.concepts = concepts))
    );
  }

  updatePresentation(): Observable<void> {
    const payload = { salesStorySettingsShown: true };

    return this.apiService.patchPresentation(this.global.getPresentation.id, payload).pipe(
      tap(() => {
        this.global.setPresentation = { ...this.global.getPresentation, ...payload };
        this.store.dispatch(
          presentationDataLoadSuccess({
            data: { ...this.global.getPresentation },
          })
        );
      }),
      catchError(() => of(null))
    );
  }

  // recompileInserts(): Observable<any> {
  //   // return this.isConcepts ? forkJoin(this.getRequestsInserts()) : of(null);
  // }

  updatePagesConfig(): Observable<any> {
    return this.isConcepts ? this.customPageService.saveCustomPagesConfig(this.getUpdatedPagesConfig()) : of(null);
  }

  recompileSalesConcepts(): Observable<any> {
    return this.isConcepts ? forkJoin(this.getRequestsSalesConcepts()) : of(null);
  }

  private getMappedSalesConcepts(data: MappedSalesConcepts): SalesConcept[] {
    return data.concepts
      .map((concept: CustomPageValues) => {
        const filteredInserts = concept.inserts.filter(
          (insert: CustomPageInsert) =>
            insert.metadata.insertType === INSERT_TYPE.variable &&
            !insert.metadata.hidden &&
            insert.metadata.showOnSetup
        );

        return {
          id: concept.config.uiId,
          label: concept.label,
          previewFilePath: concept.previewFilePath,
          description: concept.description,
          inserts: filteredInserts,
          form: this.getForm(concept, data.configs, filteredInserts),
        };
      })
      .filter((concept: SalesConcept) => concept.inserts.length > 0);
  }

  private getDependentPagesConfig(): Observable<CustomPagesConfig[]> {
    return this.store.select(getDependentPagesConfig).pipe(map(data => data.configs));
  }

  private getForm(concept: CustomPageValues, configs: CustomPagesConfig[], inserts: CustomPageInsert[]): FormGroup {
    return this.fb.group({
      ...inserts.reduce((form, insert) => {
        return {
          ...form,
          [insert.config.uiId]: [
            this.customPageService.getInsertValue(configs, concept, insert) ?? '',
            this.customPageService.getValidatorsForEditablePlaceholder(insert.metadata),
          ],
        };
      }, {}),
    });
  }

  private getInsertsToUpdate(form: FormGroup): InsertConfig[] {
    return Object.entries(form.value).map(([uiId, insertValue]) => ({ uiId, value: Number(insertValue) }));
  }

  private getSalesConceptFormDirty(): SalesConcept[] {
    return this.concepts.filter((concept: SalesConcept) => concept.form.dirty);
  }

  // private getRequestsInserts(): Observable<any>[] {
  //   return this.getSalesConceptFormDirty().reduce((acc, concept: SalesConcept) => {
  //     return [
  //       ...acc,
  //       this.customPageService.updateInsertsConfigsDirectPage(
  //         this.getInsertsToUpdate(concept.form),
  //         this.pagesConfig,
  //         concept.id
  //       ),
  //     ];
  //   }, []);
  // }

  private getUpdatedPagesConfig(): CustomPagesConfig[] {
    let updatedConfigs: CustomPagesConfig[] = [...this.pagesConfig];

    this.getSalesConceptFormDirty().forEach((concept: SalesConcept) => {
      updatedConfigs = this.customPageService.updatePagesConfig(
        this.getInsertsToUpdate(concept.form),
        updatedConfigs,
        concept.id
      );
    });

    return updatedConfigs;
  }

  private getRequestsSalesConcepts(): Observable<any>[] {
    return this.getSalesConceptFormDirty().reduce((acc, concept: SalesConcept) => {
      return [...acc, this.customPageService.getSalesConcepts({ uiId: [concept.id] })];
    }, []);
  }
}
