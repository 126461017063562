import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';

import { CustomPage, SortDirection, TableRequestParams } from '@shared/models';
import { TABLE_LABELS, PAGE_TYPES, END_PAGES_QUERY_PARAMS } from '@shared/constants';
import { SummaryTable } from '@core/class';
import { CHOOSE_CUSTOM_PAGE_HEADERS } from '../../../constants';
import { AppState } from '../../../../../reducers';
import {
  getCurrentCustomPage,
  getCustomPagesCount,
  getCustomPagesList,
  loadChooseCustomPagesList,
  setCustomPage,
  setInserts,
} from '../../../redux';
import { TimeService } from '@shared/services';

@UntilDestroy()
@Component({
  selector: 'ep-choose-custom-page',
  templateUrl: './choose-custom-page.component.html',
  styleUrls: ['./choose-custom-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseCustomPageComponent extends SummaryTable implements OnInit {
  searchControl = new FormControl('');
  dataLoading: boolean;
  selectedItem: CustomPage;
  tableLabels = TABLE_LABELS;
  pagination: any = {
    itemsPerPage: 5,
    currentPage: 1,
    hasNextPage: true,
  };

  get queryParams(): TableRequestParams {
    return {
      /**
       * TODO: why do we have direction in lower case in SummaryTable.class.ts
       *  and in upper case in all other places? This should be resolved to make it consistent
       */
      DIRECTION: this.direction.toUpperCase() as SortDirection,
      PAGE_NUMBER: this.pagination.currentPage - 1,
      PAGE_SIZE: this.pagination.itemsPerPage,
      SEARCH_VALUE: this.searchControl?.value || '',
      SHOW_PUBLISHED: true,
      SORT_FIELD: this.sortField,
      TEMPLATE: false,
      DISABLE_FILTERS: true,
    };
  }

  constructor(private store: Store<AppState>, private cdr: ChangeDetectorRef, private timeService: TimeService) {
    super();
  }

  ngOnInit(): void {
    this.setSubscriptions();
    this.setTableHeadersData(CHOOSE_CUSTOM_PAGE_HEADERS);
    this.getCustomPages();
    this.setTableData();
  }

  private setSubscriptions(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        map(() => {
          this.pagination = {
            itemsPerPage: 5,
            currentPage: 1,
            hasNextPage: true,
            totalElements: null,
          };
          this.getCustomPages();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private getCustomPages() {
    this.dataLoading = true;
    const queryParams = this.getQueryParam(this.queryParams);
    this.store.dispatch(loadChooseCustomPagesList({ queryParams }));
  }

  private getQueryParam(query: any): Record<string, string | string[] | boolean> {
    return {
      ...END_PAGES_QUERY_PARAMS,
      pageNumber: query.PAGE_NUMBER,
      pageSize: query.PAGE_SIZE,
      searchQuery: query.SEARCH_VALUE,
      direction: query.DIRECTION,
      withInserts: true,
    };
  }

  protected setTableData(): void {
    combineLatest([
      this.store.select(getCustomPagesList),
      this.store.select(getCustomPagesCount),
      this.store.select(getCurrentCustomPage),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([customPagesList, customPagesCount, currentCustomPage]) => {
        if (customPagesList && customPagesCount) {
          this.setData(customPagesList, customPagesCount, currentCustomPage);
        }
      });
  }

  private setData(customPagesList: CustomPage[], count: number, currentCustomPage: CustomPage): void {
    this.tableData = [
      //TODO: this part should move to the service
      {
        data: customPagesList
          .filter((customPage: CustomPage) => {
            if (currentCustomPage && customPage._id === currentCustomPage._id) {
              count--;

              return false;
            }

            return true;
          })
          .map((customPage: CustomPage) => {
            return {
              data: {
                ...customPage,
                pageTypeText: PAGE_TYPES[customPage.labels[0]],
                createdAt: this.timeService.getFormattedDate(customPage.createdAt),
                updatedAt: this.timeService.getFormattedDate(customPage.updatedAt),
              },
            } as any;
          }),
      },
    ];

    this.pagination.totalElements = count;
    this.dataLoading = false;
    this.cdr.markForCheck();
  }

  public onHeaderClick(): void {}

  public onRowSelect(tableDataItem: CustomPage): void {
    this.selectedItem = tableDataItem;
    this.store.dispatch(setInserts({ inserts: null }));
    this.store.dispatch(setCustomPage({ customPage: this.selectedItem }));
  }

  public onPageChange(e): void {
    this.pagination = {
      ...this.pagination,
      currentPage: e.currentPage,
    };
    this.getCustomPages();
  }
}
