import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, convertToParamMap, Params, Router } from '@angular/router';
import { SafeHtml } from '@angular/platform-browser';
import { FormControl } from '@angular/forms';

import { switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { CUSTOM_PAGES_LIST_URL } from '../../constants';
import { CustomPageService, SelectionService } from '../../services';
import { MappedEditTextCustomPage, MappedSelection, ModalPosition } from '../../models';
import { ChartsInjectService } from '@core/service';

@UntilDestroy()
@Component({
  selector: 'ep-custom-page-edit-text',
  templateUrl: './custom-page-edit-text.component.html',
  styleUrls: ['./custom-page-edit-text.component.scss'],
})
export class CustomPageEditTextComponent implements OnInit {
  loading = false;
  customPageName: string;
  htmlBody: SafeHtml;
  versionName: string;
  isShowHint = true;
  showModal = false;
  modalPosition: ModalPosition;
  textControl = new FormControl();
  elementRef: ElementRef;
  selectedText: string;
  savePageLoading = false;

  private customPageId: string;

  @ViewChild('templateRef') set content(content: ElementRef) {
    if (content) {
      this.elementRef = content;
      this.handleChartPlaceholders(content);
      this.watchForSelectionReset();
      this.watchForSelection();
    }
  }

  constructor(
    private router: Router,
    private customPageService: CustomPageService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private chartsInjectService: ChartsInjectService,
    private selectionService: SelectionService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.watchForRoute();
  }

  savePage(): void {
    this.savePageLoading = true;

    this.customPageService.setCurrentCustomPageAndVersions(null);

    this.customPageService
      .updateCustomPageHtmlBody(this.customPageId, this.elementRef.nativeElement.innerHTML)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.savePageLoading = false;
        this.leavePage();
      });
  }

  setText(text: string): void {
    this.selectionService.addRange();
    this.selectionService.setEditedContent(text, true);
    this.selectionService.resetSelection();
    this.textControl.reset();
    this.showModal = false;
  }

  leavePage(): void {
    this.router.navigate([CUSTOM_PAGES_LIST_URL, this.customPageId]);
  }

  hideHint(): void {
    this.isShowHint = false;
  }

  private watchForRoute(): void {
    this.loading = true;

    this.route.params
      .pipe(
        switchMap((params: Params) =>
          this.customPageService.getCustomPageForEditTextPage(convertToParamMap(params).get('id'))
        ),
        untilDestroyed(this)
      )
      .subscribe(
        (data: MappedEditTextCustomPage) => this.setCustomPage(data),
        () => this.leavePage()
      );
  }

  private setCustomPage(data: MappedEditTextCustomPage): void {
    this.customPageId = data.customPageId;
    this.customPageName = data.customPageName;
    this.versionName = data.versionName;
    this.htmlBody = data.htmlBody;
    this.loading = false;

    this.cdr.markForCheck();
  }

  private handleChartPlaceholders(content?: ElementRef): void {
    this.chartsInjectService.setChartPlaceholdersWidth(content);
  }

  private watchForSelection(): void {
    this.selectionService
      .watchForSelection(this.elementRef.nativeElement)
      .pipe(untilDestroyed(this))
      .subscribe((selection: MappedSelection) => {
        const selectionPlaceholder = this.createSelectionPlaceholder(selection.text);
        this.modalPosition = selection.modalPosition;
        this.selectedText = selection.text;
        this.textControl.setValue(selection.text);
        this.selectionService.setEditedContent(selectionPlaceholder);
        this.showModal = true;
      });
  }

  private watchForSelectionReset(): void {
    this.selectionService.watchForEvents(this.elementRef.nativeElement).pipe(untilDestroyed(this)).subscribe();
  }

  private createSelectionPlaceholder(text: string): HTMLElement {
    const placeholder = this.renderer.createElement('span');

    this.renderer.setAttribute(placeholder, 'class', 'selection-placeholder');
    this.renderer.setProperty(placeholder, 'innerHTML', text);

    return placeholder;
  }
}
