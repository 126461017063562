import { OnInit, Component, HostListener, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

import { ModalConfig, ModalRef } from '@assurance/bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker/bs-datepicker.component';

import { CustomPageVersions } from '@shared//models';
import { DatePickerConfig } from '@shared/constants/date-picker.constant';
import { ManageVersionsService } from '../../services';
import { Fields } from '../../enums';

@UntilDestroy()
@Component({
  selector: 'ep-manage-version',
  templateUrl: './manage-version.component.html',
  styleUrls: ['./manage-version.component.scss'],
})
export class ManageVersionComponent implements OnInit {
  @ViewChild('datePicker') datePicker: BsDatepickerDirective;

  datePickerConfig = DatePickerConfig;
  form: FormGroup;
  isConfirmVisible = false;
  versionNameToRemove: string;
  isCreationDisabled = false;

  private versionIndexToRemove: number;

  //TODO: need to change, don't use get in template like as method
  get versions(): AbstractControl[] {
    return this.form.get('versions')['controls'];
  }

  constructor(
    private modal: ModalRef,
    private config: ModalConfig<CustomPageVersions>,
    private mangeVersionService: ManageVersionsService
  ) {}

  ngOnInit(): void {
    this.form = this.mangeVersionService.initForm(this.config.data.versions);
    this.isCreationDisabled = !this.versions[this.versions.length - 1].value.startDate;
    this.watchForValidityOfVersions();
  }

  @HostListener('window:keyup.esc') onKeyUp(): void {
    this.isConfirmVisible ? this.cancel() : this.close();
  }

  create(): void {
    this.mangeVersionService.addNewFormGroup();
  }

  showConfirmModal(index: number): void {
    this.versionIndexToRemove = index;
    this.versionNameToRemove = this.mangeVersionService.getVersionName(index);
    this.isConfirmVisible = true;
  }

  remove(): void {
    const version = this.versions[this.versionIndexToRemove + 1];

    this.mangeVersionService.removeFormGroup(this.versionIndexToRemove);
    this.mangeVersionService.setFieldValue(
      this.versionIndexToRemove - 1,
      Fields.endDate,
      version ? version.value.startDate : null
    );
    this.cancel();
  }

  save(): void {
    this.modal.close(this.mangeVersionService.getFormValue());
  }

  close(): void {
    this.modal.close(null);
  }

  cancel(): void {
    this.isConfirmVisible = false;
    this.versionIndexToRemove = null;
    this.versionNameToRemove = null;
  }

  reset(index: number): void {
    this.mangeVersionService.resetFiled(index, Fields.startDate);
    this.mangeVersionService.resetFiled(index - 1, Fields.endDate);
  }

  changeStartDate(value: Date, index: number): void {
    if (value && this.datePicker) {
      this.mangeVersionService.setFieldValue(index - 1, Fields.endDate, value);
      this.mangeVersionService.setFieldValue(index, Fields.startDate, value);
    }
  }

  private watchForValidityOfVersions(): void {
    this.mangeVersionService
      .watchForValidityOfVersions()
      .pipe(untilDestroyed(this))
      .subscribe((value: boolean) => (this.isCreationDisabled = !value));
  }
}
