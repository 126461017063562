import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';

import { PresentationSharingComponent } from './presentation-sharing.component';
import { PresentationSharingService } from './presentation-sharing.service';
// import { StyleEditorModule } from '../../style-editor/style.editor.module';

@NgModule({
  declarations: [PresentationSharingComponent],
  exports: [PresentationSharingComponent],
  imports: [ModalModule.forRoot(), FormsModule, CommonModule, NgSelectModule],
  providers: [PresentationSharingService],
})
export class PresentationSharingModule {}
