import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import * as _ from 'lodash-es';
import { ColorPickerDirective } from 'ngx-color-picker';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { AuthService, ThemesAPIService } from '@assurance/um-services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { StyleItem, UiTheme } from '@core/model';
import { colours, LOGIN_ROUTER_REGEXP, LogoTypes } from './style-editor.constants';
import { Utils, Global, StyleEditorService } from '@shared/services';
import { DOCUMENT_TOKEN } from '@core/constant';
import { StyleSchemaService } from '@core/service';

@UntilDestroy()
@Component({
  selector: 'ensight-style-editor',
  templateUrl: './style-editor.html',
  // viewProviders: [ColorPickerDirective, AccordionModule, TooltipModule, TabsModule],
  providers: [ColorPickerDirective, AccordionModule, TooltipModule, TabsModule],
})
export class StyleEditorComponent implements OnInit {
  collapsedStyleEditor = true;
  showSpinner: boolean;
  selectedThemeId: number;
  modalInfo: string[];
  themes: UiTheme[];
  mode = 'create';
  // private currentTheme: UiTheme;
  styleScheme: UiTheme;
  // TODO: need to change!!!!! temporary solutions, because all interface are wronn and dont work
  hex6 = 'hex6' as any;
  searchQuery: string;
  isLoginRoute: boolean;

  private stylesList: { themes: UiTheme[] } = { themes: [] };
  private currentStyleScheme: UiTheme;
  private sizeLimitLogoImg = 512000;

  @ViewChild('childModal') childModal: any;

  constructor(
    private styleEditorService: StyleEditorService,
    private authService: AuthService,
    private global: Global,
    private utils: Utils,
    private router: Router,
    private themesAPIService: ThemesAPIService,
    @Inject(DOCUMENT_TOKEN) private document: Document,
    private styleSchemaService: StyleSchemaService
  ) {
    this.setDefaultStyle();
  }

  ngOnInit(): void {
    this.isLoginRoute = this.setIsLoginRoute();
  }

  collapseStyleEditor(): void {
    if (this.authService.isLogged && (!this.themes || !this.themes.length)) {
      this.getAllStyleSchemes(false);
    }

    this.collapsedStyleEditor = !this.collapsedStyleEditor;
    this.styleEditorService.collapsedView(this.collapsedStyleEditor);
    _.delay(this.utils.fireRefreshEventOnWindow, 1000);
  }

  //use in template
  nameOrRgb2hex(value: any) {
    let hex;
    const alpha = value.match(/rgba/) ? value.replace(/^.*,(.+)\)/, '$1') : 1;
    const rgb = value.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);

    if (!_.isUndefined((colours as any)[value.toLowerCase()])) {
      hex = (colours as any)[value.toLowerCase()];
    } else if (rgb && rgb.length === 4 && alpha === 1) {
      hex =
        '#' +
        ('0' + parseInt(rgb[1], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgb[2], 10).toString(16)).slice(-2) +
        ('0' + parseInt(rgb[3], 10).toString(16)).slice(-2);
    } else {
      hex = value;
    }

    if (hex && hex[1] === hex[2] && hex[3] === hex[4] && hex[5] === hex[6]) {
      hex = '#' + hex.slice(2, 3) + hex.slice(4, 5) + hex.slice(6, 7);
    }

    return hex;
  }

  changeColor(item: StyleItem, e: string): void {
    if (
      _.toUpper(item.value) !== _.toUpper(e) &&
      _.toUpper(this.nameOrRgb2hex(item.value)) !== _.toUpper(e) &&
      _.toUpper(item.value) !== _.toUpper(this.nameOrRgb2hex(e))
    ) {
      item.value = e;
      item.changed = true;
      const dependItem: StyleItem = this.styleEditorService.getItemByClassName(this.styleScheme, item);

      if (dependItem) {
        dependItem.value = e;
        dependItem.changed = true;
      }

      this.applyStyles();
    }
  }

  highlightElements(className: string): void {
    this.styleEditorService.highlightElements(className);
  }

  restoreItem(item: StyleItem): void {
    this.styleEditorService.restoreStyleItem(item, this.currentStyleScheme);
    this.styleEditorService.restoreStyleItem(
      this.styleEditorService.getItemByClassName(this.styleScheme, item),
      this.currentStyleScheme
    );
    this.applyStyles();
  }

  fileChange(item: StyleItem, event: any): void {
    if (!event.target.files && !event.target.files[0]) {
      return;
    }

    if (event.target.files[0] && event.target.files[0].size > this.sizeLimitLogoImg) {
      this.styleEditorService.showAlert('neutral', 'The maximum file size is <strong>512 kb</strong>');
      event.target.value = '';
    } else if (!this.styleEditorService.isFileTypeValid(event.target.files[0])) {
      const types: any = _.keys(LogoTypes);
      const extensions = types.join('</strong>, <strong>');
      this.styleEditorService.showAlert(
        'neutral',
        `<strong>Wrong</strong> logo format. Logo can be with <strong>${extensions}</strong> extensions.`
      );
    } else {
      this.styleEditorService.getBase64(event.target.files[0]).then((binaryString: string) => {
        event.target.value = '';

        if (item.value !== binaryString) {
          item.value = binaryString;
          item.changed = true;
          const dependItem: StyleItem = this.styleEditorService.getItemByClassName(this.styleScheme, item);

          if (dependItem) {
            dependItem.value = binaryString;
            dependItem.changed = true;
          }

          this.applyStyles();
        }
      });
    }
  }

  restoreStyles(): void {
    this.styleScheme = this.styleEditorService.getTheme(this.currentStyleScheme);
    this.mode = 'create';
    this.applyStyles();
  }

  saveStyle(theme?: any): void {
    const uiTheme = theme ? theme.uiTheme : this.styleScheme;

    if (uiTheme.title) {
      this.showSpinner = true;
      const style: any = this.styleEditorService.prepareReqData(theme || { uiTheme: this.styleScheme });
      this.themesAPIService.postTheme(style).subscribe(
        res => {
          this.mode = '';
          this.selectedThemeId = res.data;
          this.showSpinner = false;
          setTimeout(() => this.getAllStyleSchemes(), 500);
          this.styleEditorService.showAlert('neutral', 'New theme saved successfully.');
        },
        () => {
          this.showSpinner = false;
        }
      );
    } else {
      this.styleEditorService.showAlert('neutral', 'Please enter theme title before saving.');
    }
  }

  updateStyle(): void {
    this.showSpinner = true;
    const style: any = this.styleEditorService.prepareReqData({
      uiTheme: this.styleScheme,
    });
    this.themesAPIService.putTheme(style, style.id).subscribe(
      () => {
        this.mode = '';
        this.showSpinner = false;
        setTimeout(() => this.getAllStyleSchemes(), 500);
        this.styleEditorService.showAlert('neutral', 'Current theme saved successfully.');
      },
      () => {
        this.showSpinner = false;
        this.styleEditorService.showAlert('error', 'Could not save updates. Please, try again later.');
      }
    );
  }

  onInputText(target: any, item: StyleItem): void {
    item.value = target.value;
    this.applyStyles();
  }

  onBlockClick(e: any, block: any): void {
    if (block.sectionKey === 'other_elements_color_scheme' && e) {
      this.router.navigate(['all-elements']);
    }
  }

  exportTheme(themeId: number): void {
    // this.styleEditorService.exportTheme(theme);

    this.themesAPIService
      .getThemeById(themeId)
      .pipe(untilDestroyed(this))
      .subscribe(
        res => {
          const jsonContent = 'data:text/json;charset=utf-8,';
          const encodedUri = jsonContent + encodeURIComponent(JSON.stringify({ uiTheme: res.data }));
          const link = this.document.createElement('a');

          link.setAttribute('href', encodedUri);
          link.setAttribute('download', `${res.data.title}.json`);
          this.document.body.appendChild(link);
          link.click();
        },
        () => this.styleEditorService.showAlert('error', 'Something wrong. Try again later.')
      );
  }

  importNewTheme(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const fr = new FileReader();

      fr.onload = (e: any) => {
        const theme = JSON.parse(e.target.result);

        if (!this.styleEditorService.hasSectionKeys(theme.uiTheme)) {
          this.styleEditorService.mergeStyles(theme.uiTheme, this.styleSchemaService.getDefaultUiTheme);
        }

        this.saveStyle(theme);
      };
      fr.readAsText(file);
    }
  }

  deleteTheme(themeId: number): void {
    this.showSpinner = true;
    this.themesAPIService
      .deleteTheme(themeId)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          setTimeout(this.getAllStyleSchemes.bind(this), 500);
          this.showSpinner = false;
          this.styleEditorService.showAlert('neutral', 'Theme was deleted successfully.');
        },
        error => {
          this.showSpinner = false;

          if (error.status === 409) {
            let agencies = _.get(error, 'error.data.organizations');
            agencies = agencies ? agencies.map((e: any) => e.name) : [];
            this.showChildModal(agencies.join(', '));
          } else {
            this.styleEditorService.showAlert('error', error.message);
          }
        }
      );
  }

  editTheme(themeId: any): void {
    this.showSpinner = true;
    this.themesAPIService
      .getThemeById(themeId)
      .pipe(untilDestroyed(this))
      .subscribe(
        res => {
          this.styleScheme = _.cloneDeep(res.data) as any;
          this.currentStyleScheme = _.cloneDeep(res.data) as any;
          this.mode = 'edit';
          this.applyStyles();
          this.selectedThemeId = themeId;
          this.showSpinner = false;
        },
        () => {
          this.styleEditorService.showAlert('error', 'Something wrong. Try again later.');
          this.showSpinner = false;
        }
      );
  }

  filter(query?: string) {
    if (_.isString(query)) {
      this.searchQuery = query;
    }

    const q = this.global.replaceSymbols(this.searchQuery);
    this.themes = this.stylesList.themes.filter(theme => {
      return theme.title.search(new RegExp(q, 'i')) !== -1;
    });
  }

  applyTheme(themeId: any, event: any): void {
    event.preventDefault();
    this.showSpinner = true;
    this.themesAPIService
      .getThemeById(themeId)
      .pipe(untilDestroyed(this))
      .subscribe(
        res => {
          this.selectedThemeId = _.get(res.data, 'id');
          this.currentStyleScheme = _.cloneDeep(res.data) as any;
          this.global.setIsDefaultTheme = this.currentStyleScheme.default;
          this.styleScheme = this.styleEditorService.getTheme(res.data as any);
          this.styleEditorService.appendStylesInHtml(this.styleScheme);
          this.showSpinner = false;
        },
        () => {
          this.styleEditorService.showAlert('error', 'Something wrong. Try again later.');
          this.showSpinner = false;
        }
      );
  }

  hideChildModal(): void {
    this.childModal.hide();
  }

  private setIsLoginRoute(): any {
    const url: string = this.router.url;

    return url.search(LOGIN_ROUTER_REGEXP) !== -1;
  }

  private getAllStyleSchemes(getActualList = true): void {
    this.showSpinner = true;

    if (_.isEmpty(this.global.themesList) || getActualList) {
      this.themesAPIService
        .getAllThemes()
        .pipe(untilDestroyed(this))
        .subscribe(
          res => {
            this.onGetAllStylesSuccess(res.data);
            this.showSpinner = false;
          },
          () => {
            this.showSpinner = false;
            this.styleEditorService.showAlert('error', 'Something wrong. Try again later.');
          }
        );
    } else {
      this.onGetAllStylesSuccess(this.global.themesList);
      this.showSpinner = false;
    }
  }

  private getCurrentStyleScheme(): void {
    if (this.global.currentTheme) {
      this.onGetCurrentStyleSuccess(this.global.currentTheme);
    } else {
      this.themesAPIService
        .getCurrentTheme()
        .pipe(untilDestroyed(this))
        .subscribe(
          res => this.onGetCurrentStyleSuccess(res.data),
          () => this.styleEditorService.showAlert('error', 'Something wrong. Try again later.')
        );
    }
  }

  private onGetCurrentStyleSuccess(data: any): void {
    const style = typeof data === 'string' ? JSON.parse(data) : data;

    if (_.isEmpty(style)) {
      this.setDefaultStyle();
    } else {
      this.global.setIsDefaultTheme = style.default;
      const defaultStyle = this.styleSchemaService.getDefaultUiTheme;
      this.styleEditorService.mergeStyles(style, defaultStyle);
      this.styleScheme = this.styleEditorService.getTheme(style);
      this.currentStyleScheme = _.cloneDeep(style);
      // this.currentTheme = _.cloneDeep(style);

      if (!this.selectedThemeId) {
        this.selectedThemeId = _.get(style, 'id');
      }
    }

    if (_.isEmpty(this.stylesList.themes) || !_.find(this.stylesList.themes, { default: true })) {
      this.styleSchemaService.setTitleOfDefaultTheme('Assurance Theme');
      this.saveStyle(this.styleSchemaService.getDefaultUiTheme);
    }
  }

  private onGetAllStylesSuccess(data: any): void {
    this.stylesList.themes = data;
    this.global.themesList = data;
    this.themes = _.isEmpty(this.stylesList) ? [] : _.cloneDeep(this.stylesList.themes);
    this.getCurrentStyleScheme();
  }

  private applyStyles(): void {
    this.styleEditorService.appendStylesInHtml(this.styleScheme);
  }

  private setDefaultStyle(): void {
    this.styleScheme = _.cloneDeep(this.styleSchemaService.getDefaultUiTheme);
    this.currentStyleScheme = _.cloneDeep(this.styleSchemaService.getDefaultUiTheme);
    // this.currentTheme = _.cloneDeep(this.styleEditorService.defaultStyleScheme.uiTheme);
    this.global.setIsDefaultTheme = true;
  }

  private showChildModal(agencies: string[]): void {
    this.modalInfo = agencies;
    this.childModal.show();
  }
}
