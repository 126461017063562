import {
  Component,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  AfterViewInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import sortBy from 'lodash-es/sortBy';
import { Observable, Subject } from 'rxjs';

import { SetupService } from '../setup/setup.service';
import { Global } from '@shared/services';
import { VisualizationReadyEvent } from '@shared/models';
import { select, Store } from '@ngrx/store';
import { getPresentationLocked } from '../redux/configs/selectors';
import { getPresentationPlans } from '../presentation.selectors';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ensight-target-premium',
  templateUrl: './target-premium.component.html',
  styleUrls: ['./target-premium.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TargetPremiumComponent implements OnInit, AfterViewInit, OnDestroy {
  // TODO: wrong interface!!!!
  // plans: Data.Plan[];
  plans: any;
  isPresentationLocked$: Observable<boolean>;
  private unsubscribe$ = new Subject();

  @Output() removePageEvent = new EventEmitter();
  @Output() visualizationReadyEvent = new EventEmitter<VisualizationReadyEvent>();

  constructor(public global: Global, public setupService: SetupService, private store: Store) {}

  ngOnInit() {
    // TODO: wrong interface!!!!
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.store
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .pipe(select(getPresentationPlans), takeUntil(this.unsubscribe$))
      .subscribe(plans => (this.plans = sortBy(plans, 'order')));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.isPresentationLocked$ = this.store.pipe(select(getPresentationLocked));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  hasNotes() {
    return this.plans.some(plan => plan.configjson.metadata.note);
  }

  ngAfterViewInit() {
    this.visualizationReadyEvent.emit({ id: 'target_premium' });
  }

  removePage() {
    this.removePageEvent.emit();
  }
}
