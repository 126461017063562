<div *ngIf="(isCoverSheetLoading$ | async) || (isCoverSheetTemplatesLoading$ | async)" class="spinnerHolder">
  <ac-loader></ac-loader>
</div>

<ac-modal-header (close)="closeModal()">
  <h2 class="title">Coversheet Details</h2>
</ac-modal-header>

<ac-modal-body>
  <section  class="sidebar-container coversheet-columns"  [ngClass]="{ withTemplates: templates && templates.length }">
    <aside *ngIf="templates && templates.length" class="wrap-list">
      <ul class="list">
        <li *ngFor="let template of templates" [class.selected]="activeId === template.customFields.uiId">
          <figure (click)="chooseTemplate(template.customFields.uiId)">
            <img src="{{ 'preview_' + template.customFields.uiId | setTemplateSrc: template.files }}" alt="{{ template.name}}"/>
            <figcaption>
              <h5 class="item-title">{{ template.name }}</h5>
            </figcaption>
          </figure>
        </li>
      </ul>
    </aside>

    <div [ngSwitch]="activeId" class="template-block wrap-block">
      <ng-container *ngSwitchCase="'text'">
        <ensight-tiny-editor
          controlName="textBody"
          [form]="coverForm"
          (onKeyUp)="onModalClose($event)"
          (onEditorInstanceReady)="setEditorInstance($event)"
        ></ensight-tiny-editor>
      </ng-container>

      <ng-container *ngSwitchCase="'lowcolor'">
        <ng-container *ngTemplateOutlet="lowHighColor"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'highcolor'">
        <ng-container *ngTemplateOutlet="lowHighColor"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'allianz'">
        <ensight-cover-sheet-template
          [landscape]="true"
          [coverSheet]="coverSheet"
          [coverSheetLoaded]="isCoverSheetLoaded$ | async"
          [coverSheetTemplates]="templates"
          [selectedTemplateUid]="activeId"
          [presentationInfo]="presentationInfo"
        ></ensight-cover-sheet-template>
      </ng-container>

      <ng-container *ngSwitchCase="'allianzretirement'">
        <ensight-cover-sheet-template
          [landscape]="true"
          [coverSheet]="coverSheet"
          [coverSheetLoaded]="isCoverSheetLoaded$ | async"
          [coverSheetTemplates]="templates"
          [selectedTemplateUid]="activeId"
          [presentationInfo]="presentationInfo"
        ></ensight-cover-sheet-template>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="lowHighColor"></ng-container>
      </ng-container>
    </div>

    <ng-template #lowHighColor>
      <article class="content-article">
        <div class="container-placeholder">
          <ep-cover-sheet-placeholder></ep-cover-sheet-placeholder>
        </div>
        <form [formGroup]="coverForm" class="input-holder" (ngSubmit)="saveCoverSheet()">
          <div class="plan-container">
            <span class="title required">Proposal Title</span>
            <ep-cover-sheet-input-chips
              [field]="Fields.title"
              [control]="coverForm.get(Fields.title)">
            </ep-cover-sheet-input-chips>
            <div class="cover-error" *ngIf="coverForm.get(Fields.title).errors && coverForm.get(Fields.title).dirty">
              Title cannot be empty.
            </div>
          </div>

          <div class="plan-container cover-textarea cover-textarea-big">
            <span class="title required">Text Included in Coversheet</span>
            <ep-cover-sheet-input-chips
              [field]="Fields.body"
              [style]="{ height: '170px' }"
              [control]="coverForm.get('body')">
            </ep-cover-sheet-input-chips>
            <div class="cover-error" *ngIf="coverForm.get(Fields.body).errors && coverForm.get(Fields.body).dirty">
              Coversheet body cannot be empty.
            </div>
          </div>

          <div class="plan-container">
            <span class="title">Coversheet Signature</span>
            <div>
              <ep-cover-sheet-input-chips
                [field]="Fields.signature"
                [control]="coverForm.get(Fields.signature)">
              </ep-cover-sheet-input-chips>
            </div>
          </div>

          <div class="plan-container cover-textarea">
            <span class="title">Text Displayed in Footer</span>
            <ep-cover-sheet-input-chips
              [field]="Fields.footer"
              [style]="{ height: '72px' }"
              [control]="coverForm.get(Fields.footer)">
            </ep-cover-sheet-input-chips>
          </div>
        </form>
      </article>
    </ng-template>
  </section>
</ac-modal-body>

<ac-modal-footer>
  <ac-button type="submit" size="sm" [disabled]="!isFormEnabled()" (click)="saveCoverSheet()">
    Submit
  </ac-button>
  <ac-button [class]="'secondary'" (click)="closeModal()" size="sm">
    Cancel
  </ac-button>
</ac-modal-footer>
