import { map } from 'lodash-es';
import { createReducer, on, Action } from '@ngrx/store';

import { updateMainConfig } from './redux/configs/utils';
import { XAxisSourceEvent, DefaultState, GlobalConfig, PageConfig, DefaultConfigState } from '@shared/models';
import * as PresentationActions from './presentation.actions';
import * as SettingsActions from './redux/configs/settings.actions';
import * as VisualizationActions from './redux/configs/visualization.actions';
import * as ConfigsActions from './redux/configs/actions';
import { ActivePlan, CareerPlan, Presentation } from '@core/model';

export interface State {
  header: { disabled: boolean };
  rules: DefaultState<Record<string, string>>;
  xAxisSource: XAxisSourceEvent;
  presentation: DefaultState<Presentation>;
  plans: CareerPlan[];
  configs: DefaultConfigState<Array<GlobalConfig & PageConfig>>;
  initialLoadingConfigs: DefaultConfigState<Array<GlobalConfig & PageConfig>>;
  sharedToken: string;
}

export const initialState: State = {
  header: {
    disabled: false,
  },
  rules: {
    data: null,
    loading: false,
    loaded: false,
    error: false,
  },
  xAxisSource: {
    value: 'eoy_age',
  },
  plans: [],
  presentation: {
    data: null,
    loading: false,
    loaded: false,
    error: null,
  },
  configs: {
    data: [],
    updating: false,
    loading: false,
    loaded: false,
    error: null,
  },
  initialLoadingConfigs: {
    data: [],
    updating: false,
    loading: false,
    loaded: false,
    error: null,
  },
  sharedToken: null,
};

const presentationReducer = createReducer(
  initialState,
  on(PresentationActions.presentationRulesLoadFailure, (state, { error }) => ({
    ...state,
    rules: {
      ...state.rules,
      loading: false,
      loaded: false,
      error,
    },
  })),
  on(PresentationActions.presentationRulesLoadPending, state => ({
    ...state,
    rules: {
      ...state.rules,
      loading: true,
      loaded: false,
    },
  })),
  on(PresentationActions.presentationRulesLoadSuccess, (state, { data }) => ({
    ...state,
    rules: {
      ...state.rules,
      data,
      loading: false,
      loaded: true,
      error: false,
    },
  })),
  on(PresentationActions.presentationRulesReset, state => ({
    ...state,
    rules: { ...initialState.rules },
  })),
  on(PresentationActions.presentationDataLoadFailure, (state, { error }) => ({
    ...state,
    presentation: {
      ...state.presentation,
      loading: false,
      loaded: false,
      error,
    },
  })),
  on(PresentationActions.presentationDataLoadPending, state => ({
    ...state,
    presentation: {
      ...state.presentation,
      loading: true,
      loaded: false,
    },
  })),
  on(PresentationActions.presentationDataLoadSuccess, (state, { data }) => ({
    ...state,
    presentation: {
      ...state.presentation,
      data,
      loading: false,
      loaded: true,
      error: false,
    },
  })),
  on(PresentationActions.presentationDataReset, state => ({
    ...state,
    presentation: { ...initialState.presentation },
  })),
  on(PresentationActions.presentationStateReset, () => ({ ...initialState })),
  on(PresentationActions.presentationHeaderDisabled, (state, { disabled }) => ({
    ...state,
    header: { disabled },
  })),
  on(PresentationActions.presentationXAxisSourceUpdateSuccess, (state, { xAxisSource }) => ({
    ...state,
    xAxisSource: xAxisSource.value ? xAxisSource : state.xAxisSource,
  })),
  on(PresentationActions.presentationXAxisSourceReset, state => ({
    ...state,
    xAxisSource: initialState.xAxisSource,
  })),
  on(PresentationActions.presentationPlansUpdateSuccess, (state, { plans }) => ({
    ...state,
    plans,
  })),
  on(PresentationActions.presentationPlansReset, state => ({
    ...state,
    plans: initialState.plans,
  })),
  on(ConfigsActions.updateShowPreview, (state, { uiId, checkedState, isParentEnabled }) => {
    const mapCb = config =>
      uiId === config.config.uiId
        ? { ...config, config: { ...config.config, showPreview: checkedState, isParentEnabled } }
        : config;

    return {
      ...state,
      configs: {
        ...state.configs,
        data: state.configs.data.map(mapCb),
      },
    };
  }),
  on(ConfigsActions.setDisabledPage, (state, { uiId, checkedState }) => {
    const mapCb = config =>
      uiId === config.config.uiId
        ? {
            ...config,
            config: { ...config.config, showPreview: !checkedState },
            isDisabled: checkedState,
          }
        : config;

    return {
      ...state,
      configs: {
        ...state.configs,
        data: state.configs.data.map(mapCb),
      },
    };
  }),
  on(ConfigsActions.updatePageOrdering, (state, { orderedUiIds, orderedPageOrder }) => {
    const mapCb = config => {
      const index = orderedUiIds.findIndex(uiId => uiId === config.config.uiId);

      if (index === -1) return config;

      return {
        ...config,
        pageOrder: orderedPageOrder[index],
      };
    };

    return {
      ...state,
      configs: {
        ...state.configs,
        data: state.configs.data.map(mapCb),
      },
    };
  }),
  on(ConfigsActions.setSpreadsheetSettings, (state, { spreadsheetDrawConfig }) => ({
    ...state,
    configs: {
      ...state.configs,
      data: state.configs.data.map(config =>
        spreadsheetDrawConfig[config.config.uiId]
          ? {
              ...config,
              config: {
                ...config.config,
                spreadsheetDrawConfig: spreadsheetDrawConfig[config.config.uiId],
              },
            }
          : config
      ),
    },
  })),
  on(ConfigsActions.setActivePlans, (state, { configsActivePlans }) => ({
    ...state,
    configs: {
      ...state.configs,
      data: state.configs.data.map(config =>
        configsActivePlans[config.config.uiId]
          ? {
              ...config,
              config: {
                ...config.config,
                activePlans: configsActivePlans[config.config.uiId],
              },
            }
          : config
      ),
    },
  })),
  on(ConfigsActions.setPlanMetrics, (state, { configsPlanMetrics }) => ({
    ...state,
    configs: {
      ...state.configs,
      data: state.configs.data.map(config =>
        configsPlanMetrics[config.config.uiId]
          ? {
              ...config,
              config: {
                ...config.config,
                planMetrics: configsPlanMetrics[config.config.uiId],
              },
            }
          : config
      ),
    },
  })),
  // TODO: STATICID! Need to check
  on(ConfigsActions.updateActivePlans, (state, { uiId, metricId, fields }) => ({
    // on(ConfigsActions.updateActivePlans, (state, { uiId, id, fields }) => ({
    ...state,
    configs: {
      ...state.configs,
      data: state.configs.data.map(config =>
        config.config.uiId === uiId
          ? {
              ...config,
              config: {
                ...config.config,
                activePlans: config.config.activePlans.map((activePlan: ActivePlan) => {
                  // cover cases when we do have  carrierPlanId like 123521 or static Id is a string like - guaranteed_cumulative_premium
                  return activePlan.carrierPlanId === metricId || activePlan.metricId === metricId
                    ? { ...activePlan, ...fields }
                    : activePlan;
                }),
              },
            }
          : config
      ),
    },
  })),
  on(ConfigsActions.setPageName, (state, { uiId, pageName, planId }) => ({
    // on(ConfigsActions.setPageName, (state, { uiId, pageName, id }) => ({
    ...state,
    configs: {
      ...state.configs,
      data: state.configs.data.map(config => {
        const edit = planId ? config.config.carrierPlanId === planId : config.config.uiId === uiId;

        return edit
          ? {
              ...config,
              pageName,
            }
          : config;
      }),
    },
  })),
  on(ConfigsActions.configsPageAreaValue, (state, { area, uiId }) => {
    const data = map(state.configs.data, item => {
      if (item.config.uiId === uiId) {
        return {
          ...item,
          config: {
            ...item.config,
            chartConfig: {
              ...item.config.chartConfig,
              area,
            },
          },
        };
      }

      return item;
    });

    return {
      ...state,
      configs: {
        ...state.configs,
        data,
        loading: false,
        loaded: true,
        error: false,
      },
    };
  }),
  on(PresentationActions.presentationConfigsSettingFailure, (state, { error }) => ({
    ...state,
    configs: {
      ...state.configs,
      loading: false,
      loaded: false,
      updating: false,
      error,
    },
  })),
  on(PresentationActions.presentationConfigsSettingPending, state => ({
    ...state,
    configs: {
      ...state.configs,
      loading: true,
      loaded: false,
    },
  })),
  on(ConfigsActions.configsUpdating, (state, { value }) => ({
    ...state,
    configs: {
      ...state.configs,
      updating: value,
    },
  })),
  on(PresentationActions.presentationConfigsSettingSuccess, (state, { data }) => ({
    ...state,
    configs: {
      ...state.configs,
      data,
      loading: false,
      loaded: true,
      error: false,
    },
    initialLoadingConfigs: {
      ...state.configs,
      data,
    },
  })),
  on(PresentationActions.presentationUpdateSharedToken, (state, { sharedToken }) => ({
    ...state,
    sharedToken,
  })),
  on(ConfigsActions.presentationConfigsReset, state => ({
    ...state,
    configs: initialState.configs,
  })),
  on(ConfigsActions.removeConfig, (state, { uiId }) => ({
    ...state,
    configs: {
      ...state.configs,
      data: state.configs.data.filter(config => config.config.uiId !== uiId),
    },
  })),
  on(ConfigsActions.addConfig, (state, { config }) => ({
    ...state,
    configs: {
      ...state.configs,
      data: [...state.configs.data, config],
    },
  })),
  on(SettingsActions.updateConfigLockedPresentation, (state, { values }) => updateMainConfig(state, values)),
  on(SettingsActions.updateConfigBottomLeftPageText, (state, { values }) => updateMainConfig(state, values)),
  on(SettingsActions.updateConfigSubmitApplicationButton, (state, { values }) => updateMainConfig(state, values)),
  on(SettingsActions.updateConfigPrintAll, (state, { values }) => updateMainConfig(state, values)),
  on(SettingsActions.updateConfigMaxAge, (state, { values }) => updateMainConfig(state, values)),
  on(SettingsActions.updateConfigMinAge, (state, { values }) => updateMainConfig(state, values)),
  on(SettingsActions.updateConfigIRR, (state, { values }) => updateMainConfig(state, values)),
  on(SettingsActions.updateConfigPlansTableFields, (state, { values }) => updateMainConfig(state, values)),
  on(SettingsActions.updateConfigChartConfig, (state, { values }) => {
    const updater = config => {
      const data = values.find(value => value.uiId === config.config.uiId);

      return data
        ? {
            ...config,
            config: {
              ...config.config,
              chartConfig: {
                ...config.config.chartConfig,
                ...data.content,
              },
            },
          }
        : config;
    };

    return {
      ...state,
      configs: {
        ...state.configs,
        data: state.configs.data.map(updater),
      },
    };
  }),
  on(SettingsActions.updateConfigShortfallSettings, (state, { values }) => {
    const updater = config => {
      const data = values.find(value => value.uiId === config.config.uiId);

      return data
        ? {
            ...config,
            config: {
              ...config.config,
              shortfallSettings: {
                ...config.config.shortfallSettings,
                ...data.content,
              },
            },
          }
        : config;
    };

    return {
      ...state,
      configs: {
        ...state.configs,
        data: state.configs.data.map(updater),
      },
    };
  }),
  on(SettingsActions.updateConfigPlanMetricsTipValue, (state, { values }) => {
    const updater = config => {
      const data = values.find(value => value.uiId === config.config.uiId);

      return data
        ? {
            ...config,
            config: {
              ...config.config,
              planMetrics: config.config.planMetrics.map(planMetric => {
                const planMetricData = data.content.find(item => item.key === planMetric.key);

                return planMetricData
                  ? {
                      ...planMetric,
                      tipValue: planMetricData.tipValue,
                    }
                  : planMetric;
              }),
            },
          }
        : config;
    };

    return {
      ...state,
      configs: {
        ...state.configs,
        data: state.configs.data.map(updater),
      },
    };
  }),
  on(VisualizationActions.updateSelectedPlanId, (state, { uiId, selectedPlanId }) => {
    const updater = config => {
      return config.config.uiId === uiId
        ? {
            ...config,
            config: {
              ...config.config,
              selectedPlanId,
            },
          }
        : config;
    };

    return {
      ...state,
      configs: {
        ...state.configs,
        data: state.configs.data.map(updater),
      },
    };
  }),
  on(VisualizationActions.updateChartConfigXMax, (state, { uiId, xMax }) => {
    const updater = config => {
      return config.config.uiId === uiId
        ? {
            ...config,
            config: {
              ...config.config,
              chartConfig: {
                ...config.config.chartConfig,
                xMax,
              },
            },
          }
        : config;
    };

    return {
      ...state,
      configs: {
        ...state.configs,
        data: state.configs.data.map(updater),
      },
    };
  }),
  on(VisualizationActions.updateChartConfigXMin, (state, { uiId, xMin }) => {
    const updater = config => {
      return config.config.uiId === uiId
        ? {
            ...config,
            config: {
              ...config.config,
              chartConfig: {
                ...config.config.chartConfig,
                xMin,
              },
            },
          }
        : config;
    };

    return {
      ...state,
      configs: {
        ...state.configs,
        data: state.configs.data.map(updater),
      },
    };
  }),
  on(VisualizationActions.updateSelectedMetricKey, (state, { uiId, selectedMetricKey }) => {
    const updater = config => {
      return config.config.uiId === uiId
        ? {
            ...config,
            config: {
              ...config.config,
              selectedMetricKey,
            },
          }
        : config;
    };

    return {
      ...state,
      configs: {
        ...state.configs,
        data: state.configs.data.map(updater),
      },
    };
  }),
  on(VisualizationActions.updateChartConfigXAxisSource, (state, { uiId, xAxisSource }) => {
    const updater = config => {
      const edit = uiId ? config.config.uiId === uiId : config.config?.chartConfig?.hasOwnProperty('xAxisSource');

      return edit && config.config.chartConfig.xAxisSource !== xAxisSource
        ? {
            ...config,
            config: {
              ...config.config,
              chartConfig: {
                ...config.config.chartConfig,
                xAxisSource,
              },
            },
          }
        : config;
    };

    return {
      ...state,
      configs: {
        ...state.configs,
        data: state.configs.data.map(updater),
      },
    };
  })
);

export function reducer(state: State | undefined, action: Action): State {
  return presentationReducer(state, action);
}
