<div class="insert-dropdown-container">
  <button class="insert-dropdown-button" [ngClass]="{'insert-dropdown-open': dropdownOpen}" type="button" (click)="toggleDropdown()">
    <span *ngIf="!selectedValue">{{ title }}</span>
    <span *ngIf="selectedValue" class="insert-dropdown-value">{{ selectedValue.value }}</span>
  </button>

  <ul class="insert-dropdown-list" [ngClass]="{'insert-dropdown-open': dropdownOpen }">
    <ng-container *ngFor="let option of options; let index = index">
      <li
        *ngIf="option.value.toString().trim() !== ''"
        [ngClass]="{'selected' : selectedValue === option }"
        tabindex="0" (click)="select(index)"
        role="option"
        [innerHTML]="option.value">
      </li>
    </ng-container>
  </ul>
</div>
