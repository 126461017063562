import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as _ from 'lodash-es';

import { baseUrl, presentation, baseSharedUrl, agenciesNames } from '@core/constant';
import { getSearchQuery } from '@core/utils';
import { ShareableLinkResponse } from '@shared/models';
import { ResponseType } from '@core/model';

@Injectable()
export class PresentationSharingService {
  constructor(private http: HttpClient) {}

  public sharePresentation(presentationId: number, sharedWith: number): Observable<any> {
    return this.http
      .post(`${presentation}/${presentationId}/share`, {
        sharedWith,
      })
      .pipe(catchError(this.handleError));
  }

  public getAgencyNames(ids: Array<number | string>) {
    return this.http.post<any>(`${baseUrl}${agenciesNames}`, ids).pipe(catchError(this.handleError));
  }

  public assignPresentation(presentationId: number, assignee: number): Observable<any> {
    return this.http
      .post(`${presentation}/${presentationId}/assign`, {
        assignee,
      })
      .pipe(catchError(this.handleError));
  }

  public tagPresentationManager(presentationId: number, assignee: number): Observable<any> {
    return this.http.post(`${presentation}/${presentationId}/tag`, { assignee }).pipe(catchError(this.handleError));
  }

  public unSharePresentation(presentationId: number): Observable<any> {
    return this.http.delete(`${presentation}/${presentationId}/share`).pipe(catchError(this.handleError));
  }

  public getSharedToken(presentationId: number): Observable<ShareableLinkResponse> {
    return this.http
      .get<ResponseType<ShareableLinkResponse>>(`${baseSharedUrl}token`, {
        params: getSearchQuery({ presentationId }),
      })
      .pipe(
        map(res => res.data),
        catchError(this.handleError)
      );
  }

  public unAssignPresentation(presentationId: number): Observable<any> {
    return this.http.delete(`${presentation}/${presentationId}/assign`).pipe(catchError(this.handleError));
  }

  public unTagPresentation(presentationId: number): Observable<any> {
    return this.http.delete(`${presentation}/${presentationId}/tag`).pipe(catchError(this.handleError));
  }

  public flatAgencies(agencies: any): any[] {
    let fullAgenciesList: any[] = [];
    agencies.map((agency: any) => {
      if (agency.childrenAgencies.length) {
        const result: any = this.flatAgencies(agency.childrenAgencies);
        fullAgenciesList.push(agency);
        fullAgenciesList = this.unionArrays(fullAgenciesList, result);
      } else {
        fullAgenciesList.push(agency);
      }
    });

    return fullAgenciesList;
  }

  copyToClipboard(url: string, clientName: string): Observable<void> {
    const htmlLink = `<a href="${url}">Ensight Presentation - ${clientName}</a>`;
    const clipboardPromise = navigator.clipboard.write([
      new ClipboardItem({
        'text/plain': new Blob([url], { type: 'text/plain' }),
        'text/html': new Blob([htmlLink], { type: 'text/html' }),
      }),
    ]);

    return from(clipboardPromise);
  }

  //TODO: need to remove this method
  public generateSharedLink(token: string) {
    return `${location.origin}/shared-presentation/${token}`;
  }

  private handleError(error: any) {
    return throwError(`${error.status} - ${error.statusText}`);
  }

  private unionArrays(objValue: any[], srcValue: any[] | Record<string, unknown>): any[] {
    if (_.isArray(objValue)) {
      return objValue.concat(srcValue);
    } else {
      (objValue as any).push(objValue);

      return objValue;
    }
  }
}
