import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { Field } from '../../models';
import { Fields } from '../../enums';
import { TimeService } from '@shared/services';
import { TimeZones } from '@core/constant';
import { CustomPageVersion } from '@shared/models';

@Injectable()
export class ManageVersionsService {
  private form: FormGroup;

  private get versions(): FormArray {
    return this.form.get('versions') as FormArray;
  }

  constructor(private fb: FormBuilder, private timeService: TimeService) {}

  initForm(versions: CustomPageVersion[]): FormGroup {
    this.form = this.fb.group({
      versions: this.fb.array(this.getFromGroups(versions)),
    });

    return this.form;
  }

  addNewFormGroup(): void {
    this.versions.push(this.getFormGroup({ versionName: '', startDate: null, endDate: null }));
  }

  removeFormGroup(index: number): void {
    this.versions.removeAt(index);
    this.versions.markAsDirty();
  }

  getVersionName(index: number): string {
    return this.getField(index, Fields.versionName).value;
  }

  setFieldValue(index: number, field: Field, value: Date): void {
    this.getField(index, field).setValue(value, { emitEvent: true });
    this.getField(index, field).markAsDirty();
  }

  resetFiled(index: number, field: Field): void {
    this.getField(index, field).setValue(null);
    this.getField(index, field).markAsDirty();
  }

  //this method use for disable/enable create version button
  watchForValidityOfVersions(): Observable<boolean> {
    return this.versions.valueChanges.pipe(
      map((versions: CustomPageVersion[]) => !!versions[versions.length - 1].startDate),
      distinctUntilChanged()
    );
  }

  getFormValue(): CustomPageVersion[] {
    const formValue: CustomPageVersion[] = this.form.getRawValue().versions;

    return formValue.map((item: CustomPageVersion) => {
      return {
        ...item,
        startDate: this.setTimeZone(item.startDate),
        endDate: this.setTimeZone(item.endDate),
      };
    });
  }

  setTimeZone(date: string): string {
    if (!date) {
      return date;
    }

    return this.timeService.setStartOf(this.timeService.setTimeZone(date, TimeZones.EDT, true), 'day').format();
  }

  private getField(index: number, field: Field): AbstractControl {
    return this.versions.controls[index].get(field);
  }

  private getFromGroups(versions: CustomPageVersion[]): FormGroup[] {
    return versions.map((version: CustomPageVersion) => this.getFormGroup(version));
  }

  private getFormGroup(version: CustomPageVersion): FormGroup {
    return new FormGroup({
      [Fields.pageId]: new FormControl(version.pageId ? version.pageId : ''),
      [Fields.versionName]: new FormControl(version.versionName, [Validators.maxLength(64), Validators.required]),
      [Fields.startDate]: new FormControl(version.startDate ? new Date(version.startDate) : null),
      [Fields.endDate]: new FormControl(version.endDate ? new Date(version.endDate) : null),
    });
  }
}
