import { Inject, Injectable } from '@angular/core';

import { toggleNavbar } from './navbar.constants';
import { CustomPageValues, PageConfig, PlanOption } from '@shared/models';
import { WINDOW_TOKEN } from '@core/constant';
import { CareerPlan } from '@core/model';

@Injectable()
export class NavbarService {
  constructor(@Inject(WINDOW_TOKEN) private window: Window) {}

  public getNavbarCollapseState(): boolean {
    return this.window.innerWidth <= toggleNavbar;
  }

  public getHeaderHeight(): number {
    return document.querySelector('.details-header.container-full')
      ? document.querySelector('.details-header.container-full').clientHeight
      : 0;
  }

  public getScrollValuePx(): number {
    return window.pageYOffset || document.documentElement.scrollTop;
  }

  public findIndexOfPage(navbarPages: PageConfig[] | CustomPageValues[], selectedPage: CustomPageValues): number {
    return selectedPage.parentUiId && !selectedPage?.config.isSalesConcept
      ? navbarPages.findIndex(
          page => page.config.uiId === selectedPage.config.uiId && page.parentUiId === selectedPage.parentUiId
        )
      : navbarPages.findIndex(page => page.config.uiId === selectedPage.config.uiId);
  }

  public mergeAllPages(dndList: PageConfig[], linkedPages, endPages: CustomPageValues[]) {
    let navbarPages = [];
    linkedPages.forEach((page, i) =>
      page
        ? (navbarPages = [...navbarPages, ...this.checkDisabledSalesConcept(page)])
        : dndList[i].config.showPreview
        ? (navbarPages = [...navbarPages, dndList[i]])
        : (navbarPages = [...navbarPages])
    );

    return [...navbarPages, ...endPages];
  }

  private checkDisabledSalesConcept(pages) {
    return pages.filter(page => page.showPreview || page.config?.showPreview);
  }

  public isDependentPagesLocked(page: PageConfig, salesConcepts: CustomPageValues[]): boolean {
    if (page.config.isSalesConcept && salesConcepts) {
      const salesConcept = salesConcepts.find(salesConcept => salesConcept.config.uiId === page.config.uiId);

      return salesConcept?.dependentPagesLocked;
    }

    return false;
  }

  isSaleConceptLockedAndEnabled(page: PageConfig, salesConcepts: CustomPageValues[]): boolean {
    if (page.config.isSalesConcept && salesConcepts) {
      const customPage = salesConcepts.find(customPage => customPage.config.uiId === page.config.uiId);

      return customPage?.isPageLockedAndEnabled;
    }

    return false;
  }

  public extendPlanOptions(planOptions: PlanOption[], plans: CareerPlan[]): PlanOption[] {
    return planOptions.map((option: PlanOption) => {
      const plan = plans.find(plan => plan.id === option.id);

      return { ...option, productType: plan.configjson.metadata.product_type };
    });
  }
}
