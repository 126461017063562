import { ModalDimensions, ModalType } from '@shared/models';
import { ConfirmModalComponent } from '../../../components/modals/confirm-modal/confirm-modal.component';
import { PlanImportModalComponent } from '../../../components/modals/plan-import-modal/plan-import-modal.component';

export const seModalConfigs = {
  placeholderEditing: {
    autoFocus: true,
    customFooter: true,
    width: 600,
  },
  imagePlaceholderEditing: {
    headerTitle: 'Image',
  },
  pageNotFound: {
    headerTitle: 'Page Not Found',
    apply: {
      title: 'Home',
    },
    hideCloseIcon: true,
    ignoreClose: true,
    ignoreBackdropClick: true,
    customFooter: true,
  },
  caseSelection: {
    headerTitle: 'Select Case',
    customFooter: true,
    maxWidth: 'auto',
  },
  customPageCopy: {
    headerTitle: 'Create Custom Page Copy',
    customFooter: true,
  },
  addTab: {
    headerTitle: 'Add Tab',
    customFooter: true,
  },
  editTab: {
    headerTitle: 'Edit Tab Name',
    customFooter: true,
  },
  removeTab: {
    headerTitle: 'Remove Tab',
    customFooter: true,
  },
  addMetric: {
    headerTitle: 'Add New Metric',
    customFooter: true,
  },
  removeMetric: {
    headerTitle: 'Remove Metric',
    customFooter: true,
  },
};

export const MODAL_DIMENSIONS = new Map<ModalType | 'default', ModalDimensions>([
  [ConfirmModalComponent, { height: 'auto', width: '600px' }],
  [PlanImportModalComponent, { height: 'auto', width: '900px', maxWidth: '85vw' }],
  ['default', { height: '400px', width: '600px' }],
]);

export const MIDDLE_MODAL_DIMENSIONS: ModalDimensions = {
  height: '80vh',
  width: '60vw',
};

export const notificationTypes = {
  NOTHING_FOUND_ON_SEARCH: {
    title: 'Nothing found',
    message: 'Try to modify the search to see more results',
    type: 'no-cases-found',
  },
};
