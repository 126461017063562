<div class="modal-container">
  <div class="modal-header">
    <div class="modal-header-title">{{ editMode ? title :  activeInsertTypeState?.title }}</div>
    <div (click)="closeModal()" class="header-close">
      <div class="esc">esc</div>
      <div class="ensight-icon-se-close"></div>
    </div>
  </div>

  <div class="modal-body">
    <div [ngSwitch]="activeInsertTypeState?.id">
      <ng-container *ngSwitchCase="chartConfigurationStateId">
        <ep-configuration
          [configurationStateId]="chartConfigurationStateId"
          [plansConfigData]="plansConfigData"
          [id]="id"
        ></ep-configuration>
      </ng-container>

      <ng-container *ngSwitchCase="chartStyleConfigurationStateId">
        <ep-configuration
          [configurationStateId]="chartStyleConfigurationStateId"
          [id]="id"
        ></ep-configuration>
      </ng-container>

      <ng-container *ngSwitchCase="textConfigurationStateId">
        <ep-configuration
          [configurationStateId]="textConfigurationStateId"
          [id]="id"
        ></ep-configuration>
      </ng-container>

      <ng-container *ngSwitchCase="productsDescriptionStateId">
        <ep-configuration
          [configurationStateId]="productsDescriptionStateId"
          [id]="id"
        ></ep-configuration>
      </ng-container>

      <ng-container *ngSwitchCase="apiCallConfigurationStateId">
        <ep-configuration
          [configurationStateId]="apiCallConfigurationStateId"
          [id]="id"
        ></ep-configuration>
      </ng-container>

      <ng-container *ngSwitchCase="postApiCallStateId">
        <ep-configuration
          [configurationStateId]="postApiCallStateId"
          [id]="id"
        ></ep-configuration>
      </ng-container>

      <ng-container *ngSwitchCase="apiCallResponseStateId">
        <ep-multiple-configurations
          [configurationStateId]="apiCallResponseStateId"
          [id]="id"
        ></ep-multiple-configurations>
      </ng-container>

      <ng-container *ngSwitchCase="customTableStateId">
        <ep-configuration
          [configurationStateId]="customTableStateId"
          [id]="id"
        ></ep-configuration>
      </ng-container>

      <ng-container *ngSwitchCase="customTableColumnsStateId">
        <ep-multiple-configurations
          [configurationStateId]="customTableColumnsStateId"
          [id]="id"
        ></ep-multiple-configurations>
      </ng-container>

      <ng-container *ngSwitchCase="customMetricStateId">
        <ep-custom-metrics
          [id]="id"
          [editMode]="editMode"
          [configurationStateId]="customMetricStateId"
          [customMetricFormulaStateId]="customMetricFormulaStateId"></ep-custom-metrics>
      </ng-container>

      <ng-container *ngSwitchCase="variableConfigurationStateId">
        <ep-configuration
          [configurationStateId]="variableConfigurationStateId"
          [id]="id"
        ></ep-configuration>
      </ng-container>

      <ng-container *ngSwitchCase="buttonConfigurationStateId">
        <ep-configuration
          [configurationStateId]="buttonConfigurationStateId"
          [id]="id"
        ></ep-configuration>
      </ng-container>

      <ng-container *ngSwitchCase="chartMetricsStateId">
        <ep-multiple-configurations
          [plansConfigData]="plansConfigData"
          [plansConfigMeta]="plansConfigMeta"
          [configurationStateId]="chartMetricsStateId"
          [id]="id"
        ></ep-multiple-configurations>
      </ng-container>

      <ng-container *ngSwitchCase="insertTypeStateId">
        <ep-insert-type
          [selectedInsertType]="selectedInsertType"
        ></ep-insert-type>
      </ng-container>

      <ng-container *ngSwitchCase="customPageStateId">
        <ep-choose-custom-page></ep-choose-custom-page>
      </ng-container>

      <ng-container *ngSwitchCase="insertsStateId">
        <ep-tree-inserts></ep-tree-inserts>
      </ng-container>

      <ng-container *ngSwitchCase="imageStateId">
        <ep-set-image
          [id]="id"
          (uploadImage)="setImageOnUpload($event)"
          (selectImage)="setImageSelect($event)"
          (deleteImage)="setImageDelete($event)"
          (loadedImage)="setImageLoaded($event)"
        ></ep-set-image>
      </ng-container>

      <ng-container *ngSwitchCase="dropdownStateId">
        <ep-configuration
          [configurationStateId]="dropdownStateId"
          [id]="id"
        ></ep-configuration>
      </ng-container>

      <ng-container *ngSwitchCase="productSelectorStateId">
        <ep-configuration
          [configurationStateId]="productSelectorStateId"
          [id]="id"
        ></ep-configuration>
      </ng-container>

      <ng-container *ngSwitchCase="tabStateId">
        <ep-configuration
          [configurationStateId]="tabStateId"
          [id]="id"
        ></ep-configuration>
      </ng-container>

      <ng-container *ngSwitchCase="tabAreaStateId">
        <ep-multiple-configurations
          [configurationStateId]="tabAreaStateId"
          [id]="id"
        ></ep-multiple-configurations>
      </ng-container>

    </div>
  </div>

  <div class="modal-footer">
    <span *ngIf="isRequiredFieldsLabelShown">All fields are required</span>
    <div class="buttons-block">
      <se-button *ngIf="isBackBtnShown" class="secondary" (click)="back()">
        Back
      </se-button>
      <se-button
        (click)="next()"
        [disabled]="!isValidForm || !selectedInsertType"
      >
        {{ buttonLabel }}
      </se-button>
    </div>
  </div>
</div>
