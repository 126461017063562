<ng-container *ngIf="options.isPDF; else nonPdfContent">
  <ng-container *ngIf="!isSinglePolicyOrRTEOrCharges">
    <a [href]="shareableLink" target="_blank">
      <ng-container *ngTemplateOutlet="chartContent"></ng-container>
    </a>
  </ng-container>
  <ng-container *ngIf="isSinglePolicyOrRTEOrCharges">
    <ng-container *ngTemplateOutlet="chartContent"></ng-container>
  </ng-container>
</ng-container>

<ng-template #nonPdfContent>
  <ng-container *ngTemplateOutlet="chartContent"></ng-container>
</ng-template>

<ng-template #chartContent>
  <div
    *ngIf="isPieOrDonutChart && !isNoData"
    class="chart-legend"
    [ngClass]="{
      'legend-right': legendPosition === 'right',
      'legend-bottom': legendPosition === 'bottom'
    }"
  >
    <ng-container *ngTemplateOutlet="chart"></ng-container>

    <div>
      <div>
        <div class="chart-legend-title" *ngIf="options.chart.legendTitle">
          {{ options.chart.legendTitle }}
        </div>
        <div class="chart-legend-subtitle" *ngIf="options.chart.legendSubtitle">
          {{ options.chart.legendSubtitle }}
        </div>
      </div>

      <div
        class="legend-container"
        [ngStyle]="{
          'flex-direction':
            (!isSecondLineDescriptionLegend && legendPosition) === 'bottom'
              ? 'row'
              : 'column'
        }"
      >
        <div
          *ngFor="let item of legendData"
          (mouseenter)="focusOnChartDataById(item.id)"
          (mouseleave)="resetFocusOnChartData()"
          class="legend-item"
        >
          <div
            [ngClass]="{
              'item-dimmed': item.dimmed,
              'has-legend-description': item.description
            }"
            class="legend-item-container"
          >
            <span
              [ngStyle]="{ 'background-color': item.color }"
              class="color-box"
            ></span>
            <div>
              <div
                class="legend-item-title"
                [ngClass]="{ 'legend-item-title-bold': item.description }"
              >
                {{ item.title }}
              </div>
              <div
                *ngIf="item.description"
                class="legend-item-second-line-description"
              >
                <span *ngIf="item.description.secondLineDescription">
                  {{ item.description.secondLineDescription }}:
                </span>
                <span class="legend-item-value">
                  {{ item.description.nameAsValue }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="!isNoData && !isPieOrDonutChart">
    <ng-container *ngTemplateOutlet="chart"></ng-container>
  </ng-container>

  <ng-template #chart>
    <div
      #c3Chart
      class="c3Chart"
      [ngClass]="{
        'tooltip-value-hide': isPieOrDonutChart && isValueTooltipHide,
        'container-pie-donut': isPieOrDonutChart
      }"
    ></div>
  </ng-template>

  <div
    *ngIf="isNoData"
    class="no-data-message"
    [ngStyle]="{ height: options.chart.height + 'px' }"
  >
    <p>No Data Available</p>
  </div>
</ng-template>
