import { Component, OnInit } from '@angular/core';
import { Global } from '@shared/services';
import { IAMService } from '@assurance/um-services';
import { select, Store } from '@ngrx/store';
import { getPresentationGlobalConfig } from '../../redux/configs/selectors';

@Component({
  selector: 'ensight-pdf-footer-text',
  templateUrl: './pdf-footer-text.component.html',
  styleUrls: ['./pdf-footer-text.component.scss'],
})
export class PdfFooterTextComponent implements OnInit {
  isBottomLeftTextAllowed: boolean;
  isSharedPresentation: boolean;
  // TODO: need to change
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  globalConfig$ = this.store.pipe(select(getPresentationGlobalConfig));

  constructor(private iamService: IAMService, private global: Global, private store: Store) {}

  ngOnInit() {
    this.isSharedPresentation = this.global.isSharedPresentation();
    this.isBottomLeftTextAllowed = this.isSharedPresentation
      ? false
      : this.iamService.hasGroupAccess('display_bottom_left_page_text');
  }
}
