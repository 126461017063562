<div
  class="wrapper assurance-chart-wrapper"
  *ngIf="options && data"
  [ngClass]="[
    isSinglePolicyOrRTEOrCharges ? 'padding_chart_pdf' : '',
    options.id
  ]"
  [class.mobile]="mobileDevice"
  [class.pinMoved]="pinMoved"
  [class.dark-theme]="chartTheme === 'darkTheme'"
  [class.clustered-bar-chart]="isClusteredBarChart"
>
<ep-c3-chart
  *ngIf="isS3JSChart"
  #c3chart
  [data]="chartData"
  [options]="chartOptions"
  (click)="clickEventHandleC3($event)"
  (mousemove)="mouseMoveEventHandleC3($event)"
  (mouseup)="mouseEventHandle(false)"
  (mouseover)="mouseoverEventHandleC3($event)"
  (mouseout)="multiBarMouseoutHandler()"
  (touchstart)="touchEventHandle(true)"
  (touchend)="touchEventHandle(false)"
  (onrendered)="chartIsReadyEmitC3()"
  (onMovePinByClick)="onPinMoveByClickingOnChart($event)"
  [class.custom-font-size]="(!!chartFontSize || !!options?.chart?.chartFontSizeAxesLabels) && !isClusteredBarChart"
  [style.font-size.px]="chartFontSize"
  [style.font-size]="options?.chart?.chartFontSizeAxesLabels"
></ep-c3-chart>

  <nvd3
    *ngIf="!isS3JSChart"
    #chart
    [attr.id]="chart.options.id"
    [data]="chartData"
    [options]="chartOptions"
    (mouseup)="mouseEventHandle(false)"
    (touchstart)="touchEventHandle(true)"
    (touchend)="touchEventHandle(false)"
    [class.custom-font-size]="!!chartFontSize"
    [style.font-size.px]="chartFontSize"
  ></nvd3>

  <div
    class="pin-wrapper"
    *ngIf="pinPosition && (options.pinValue || options.pinValue === 0) && data && data.length"
  >
    <div
      #pin
      class="draggable-pin-area"
      [style.left.px]="pinPosition"
      [ngStyle]="pinBlockWidth ? { 'width.px': pinBlockWidth } : {}"
      [class.animated]="!pinMoved"
      (mousedown)="mouseEventHandle(true)"
      (mouseup)="mouseEventHandle(false)"
      (touchstart)="mouseEventHandle(true)"
      (touchend)="mouseEventHandle(false)"
      (touchmove)="elementTouchmoveHandler($event)"
    >
      <div
        #pinNumber
        [style.width.px]="pinBlockWidth || NVD3PinWidth"
        class="aapp-custom-tooltip-assur a-app-chart-pin-background"
      >
        <h1
          #tooltipNumber
          [style.marginTop.px]="options?.chart?.pinMarginTop"
          class="current-value"
          [style.font-size]="options?.chart?.chartFontSizeXAxisValuePin"
        >
          {{ options.pinValue }}
        </h1>
        <div
          class="chart-pin-point"
          (mousedown)="mouseEventHandle(true)"
          (mouseup)="mouseEventHandle(false)"
        >
          <div #pinLine class="chart-pin-line"></div>
        </div>
        <div
          #tooltipContainer
          class="tooltip-wrapper"
          [ngClass]="{
            'tooltip-position-left': tooltipPositionLeft,
            'tooltip-position-right': !tooltipPositionLeft,
            'tooltip-position-shifted-tooltip-left':
              tooltipPositionLeft && tooltipDataByPinPosition?.length > 5,
            'tooltip-position-shifted-tooltip-right':
              !tooltipPositionLeft && tooltipDataByPinPosition?.length > 5
          }"
          [ngStyle]="{
            top: topPosition ? topForTopPosition : undefined,
            bottom: !topPosition ? topForBottomPosition : undefined
          }"
          [hidden]="!tooltipDataByPinPosition"
        >
          <ep-chart-tooltip
            [data]="tooltipDataByPinPosition"
            [chartOptions]="options"
            (stopPin)="stopPin()"
            *ngIf="tooltipDataByPinPosition"
          ></ep-chart-tooltip>
        </div>
      </div>
    </div>
  </div>
</div>
