import { Injectable } from '@angular/core';

import { select, Store } from '@ngrx/store';
import { IAMService, UmNodeAPIService, UserService } from '@assurance/um-services';
import { AlertService } from '@se/common';
import { first, switchMap } from 'rxjs/operators';

import { AppState } from '../../../reducers';
import { PresentationService } from '../../../components/presentation/presentation.service';
import { SetupService } from '../../../components/presentation/setup/setup.service';
import { PDFGenerationService } from '../../../components/presentation/pdf-generation/pdf-generation.service';
import {
  getPermittedConfigs,
  getPresentationConfigs,
  getPresentationInitialLoadingConfigs,
} from '../../../components/presentation/redux/configs/selectors';
import { SocketService, Global, APIService, TimeService } from '@shared/services';
import { combineLatest } from 'rxjs';
import { getDiff, rdiffResult } from 'recursive-diff';
import { GA_NOT_SET } from '@shared/constants';
import { LocalStorageService } from '@core/service';

@Injectable()
export class SharedSocketService extends SocketService {
  constructor(
    public userService: UserService,
    public iamService: IAMService,
    public umNodeAPIService: UmNodeAPIService,
    public presentationService: PresentationService,
    public setupService: SetupService,
    public global: Global,
    public api: APIService,
    public pdfGenerationService: PDFGenerationService,
    public alertService: AlertService,
    public store: Store<AppState>,
    public localStorage: LocalStorageService,
    private timeService: TimeService
  ) {
    super(global, iamService, umNodeAPIService, api, pdfGenerationService, alertService, store, localStorage);
  }

  public sendPDFExport(presentationId: number, pages?: string[], target = 'setup'): void {
    // Currently only one scenario when we need to set pages array it's target premium ['life_products', 'target_premium']
    const hasPages = pages && pages.length;
    let url = `presentation/${presentationId}/export/pdf`;

    if (hasPages) {
      url = `${url}?${this.generatePagesQueryParams(pages, true)}`;
    }

    this.store
      .pipe(
        select(getPermittedConfigs),
        first(),
        switchMap(configs => this.generatePDFRequest(url, !!hasPages, configs))
      )
      .subscribe(options => this.sendMessage(options));

    gtag('event', `PDF Download - ${target == 'view' ? 'From Presentation View' : 'From Presentation Setup'}`, {
      EventCategory: 'PDF Download',
      EventAction: target == 'view' ? 'From Presentation View' : 'From Presentation Setup',
      EventLabel: GA_NOT_SET,
      PresentationID: this.global.getActivePresentationId,
      PresentationName: this.global.getPresentation.name,
      TemplateID: GA_NOT_SET,
      TemplateName: GA_NOT_SET,
      UserID: this.userService.user.id,
      AgencyId: this.userService.organization.id,
      AgencyName: this.userService.organization.name,
      PresentationPage: GA_NOT_SET,
      ElementName: GA_NOT_SET,
      EventTimestamp: this.timeService.getPstTimeForGa4(),
    });
  }

  public sendPDFExportShared(sharedToken: string): void {
    const url = `shared-presentation/${sharedToken}/export/pdf`;
    combineLatest([this.store.select(getPresentationConfigs), this.store.select(getPresentationInitialLoadingConfigs)])
      .pipe(
        first(),
        switchMap(([configs, initialConfigs]) => {
          const diff: rdiffResult[] = getDiff(initialConfigs, configs);

          return this.generatePDFRequest(url, false, configs, true, sharedToken, true, diff);
        })
      )
      .subscribe(options => this.sendMessage(options));
  }

  public sendPDFExportCustomPage(pageId: string, landscape: boolean, presentationId?: number): void {
    const url = presentationId
      ? `custom-pages/${pageId}/export/pdf?presentationId=${presentationId}`
      : `custom-pages/${pageId}/export/pdf`;

    this.store
      .pipe(
        select(getPermittedConfigs),
        first(),
        switchMap(configs => this.generatePDFRequest(url, true, configs, landscape))
      )
      .subscribe(options => this.sendMessage(options));
  }
}
