import { createSelector } from '@ngrx/store';

import { AppState } from '../../../../reducers';

import { CoverSheetModalState } from './cover-sheet-modal.reducer';
import { CoverLetterTemplates } from './cover-sheet-modal.interfaces';

import { State as PresentationState } from './../../presentation.reducer';
import { getCoverLetterData, getDependentPermissions } from '@ngrx-app/global.selectors';
import { CustomPageValues, CoverLetter } from '@shared/models';

export const getCoverSheetModalReducer = (state: AppState): CoverSheetModalState => state.coverLetterTemplates;

export const getPresentationReducer = (state: AppState): PresentationState => state.presentation;

export const getRules = createSelector(getPresentationReducer, (state: PresentationState) => state.rules.data);

export const getCoverLetterTemplates = createSelector(
  getCoverSheetModalReducer,
  (state: CoverSheetModalState) => state.coverLetterTemplates
);

export const getCoverLetterTemplatesData = createSelector(
  getCoverLetterTemplates,
  (coverLetterTemplates: CoverLetterTemplates) => coverLetterTemplates.data
);

export const getCoverLetterRequiredTemplates = createSelector(
  getCoverSheetModalReducer,
  (state: CoverSheetModalState) => state.coverLetterRequiredTemplates
);

export const getCoverLetterRequiredTemplatesData = createSelector(
  getCoverLetterRequiredTemplates,
  (coverLetterRequiredTemplates: CoverLetterTemplates) => coverLetterRequiredTemplates.data
);

export const getCoverLetterTemplatesFiltered = createSelector(
  getCoverLetterTemplatesData,
  getCoverLetterData,
  getDependentPermissions,
  (templates: any[], coverLetterData: any, dependentPermissionsData) => {
    let coverSheetTemplates = templates;

    coverSheetTemplates = coverSheetTemplates.filter(template => template.customFields.uiId !== 'highcolor'); // TODO need to be deleted when HIGH color template will be implemented

    const savedCoverSheet = coverLetterData;
    const dependentPermissions = dependentPermissionsData.coverPages || [];

    if (savedCoverSheet && !dependentPermissions.includes(savedCoverSheet.uiId)) {
      return coverSheetTemplates;
    } else {
      return coverSheetTemplates.filter(template => dependentPermissions.includes(template.customFields.uiId));
    }
  }
);

export const getCoverLetterRequired = createSelector(
  getCoverLetterRequiredTemplatesData,
  getDependentPermissions,
  (templates: any[], dependentPermissionsData) => {
    const dependentPermissions = dependentPermissionsData.coverPagesRequired || [];

    return templates.filter(
      template =>
        dependentPermissions.includes(template.customFields.uiId) && template.customFields.uiId !== 'highcolor'
    ); // TODO need to be deleted when HIGH color template will be implemented
  }
);

export const getCoverLetterTemplatesLoading = createSelector(
  getCoverSheetModalReducer,
  (state: CoverSheetModalState) => state.coverLetterTemplates.loading
);

export const getCoverLetterTemplatesLoaded = createSelector(
  getCoverSheetModalReducer,
  (state: CoverSheetModalState) => state.coverLetterTemplates.loaded
);

export const getCoverLetterRequiredTemplatesLoaded = createSelector(
  getCoverSheetModalReducer,
  (state: CoverSheetModalState) => {
    return state.coverLetterRequiredTemplates.loaded;
  }
);

export const getCoverLetterWithRequired = createSelector(
  getCoverLetterData,
  getCoverLetterRequired,
  getCoverLetterTemplatesLoaded,
  (coverLetter: CoverLetter, coverLettersRequired: CustomPageValues[], coverLettersRequiredLoaded: boolean) => {
    return {
      coverLetter,
      coverLettersRequired,
      coverLettersRequiredLoaded,
    };
  }
);
