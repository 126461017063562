import { Placeholder } from '@core/model';
import { INSERT_TYPE } from '@core/enums';

export const imagesList = {
  u01da8ca33b69f3930f1682334782af2c: [
    {
      name: '1638184834578 - pic.jpg',
      type: 'image/jpeg',
      url: 'blob:https://test-ep.ts.assuranceapp.com/4cbc694e-c96e-44fe-9ea5-48e40bfb6579',
      upload: true,
      delete: false,
      loading: false,
      selected: true,
    },
  ],
};

export const placeholderData = [
  {
    customFields: {
      carrierUiIds: [],
      linkedTo: [],
      insertIds: [],
      uiId: 'u4b530f0922cbd5d90d504ed73fc0efb5',
      dependentPages: [],
      metadata: {
        placeholderName: 'pic',
        width: '500',
        height: '500',
        selectedImage: '1638184380000 - mountain.jpg',
        id: 'u4b530f0922cbd5d90d504ed73fc0efb5',
        insertType: 'image',
      },
      editable: true,
    },
    labels: ['insert'],
    urls: [],
    filesLinks: ['61a4b5c3f4caeb0fa1973afb/1638184380000 - mountain.jpg'],
    _id: '61a4b5c3f4caeb0fa1974fas',
    portal: 'dvp',
    type: 'insert',
    name: 'insert',
    label: 'insert',
    userId: 1,
    agencyId: 0,
    createdAt: '2021-11-22T22:22:22.793Z',
    updatedAt: '2021-11-22T22:22:22.793Z',
  },
];

export const imagePlaceholder = {
  name: '1638184380000 - mountain.jpg',
  url: 'blob:https://test-ep.ts.assuranceapp.com/4cbc694e-c96e-44fe-9ea5-48e40bfb6579',
  nativeUrl: '61a4b5c3f4caeb0fa1973afb/1638184380000 - mountain.jpg',
  upload: false,
  selected: true,
  delete: false,
  loading: true,
  type: null,
};

export const metadata = [
  {
    placeholderName: 'text1',
    placeholderText: 'lorem ipsum',
    placeholderMaxLength: 100,
    id: 'u6af68cece22f28a525a9299a49fba3f8',
    insertType: 'text',
  },
  {
    placeholderName: '1',
    placeholderKey: '1',
    placeholderDecimals: 0,
    placeholderMinValue: 1,
    placeholderMaxValue: 10,
    placeholderDefaultValue: 1,
    id: 'u326273f05a68ea6890f131a5d9be4dd6',
    insertType: 'variable',
  },
  {
    placeholderName: 'text2',
    placeholderText: 'test',
    placeholderMaxLength: 100,
    id: 'ud0b78fcf5c72615ab0f0e9a18bb43d3e',
    insertType: 'text',
  },
];

export const orderedMetadata: Placeholder[] = [
  {
    placeholderName: 'text1',
    placeholderText: 'lorem ipsum',
    placeholderMaxLength: 100,
    id: 'u6af68cece22f28a525a9299a49fba3f8',
    insertType: INSERT_TYPE.text,
    order: 0,
  },
  {
    placeholderName: 'text2',
    placeholderText: 'test',
    placeholderMaxLength: 100,
    id: 'ud0b78fcf5c72615ab0f0e9a18bb43d3e',
    insertType: INSERT_TYPE.text,
    order: 1,
  },
  {
    placeholderName: '1',
    placeholderKey: '1',
    placeholderDecimals: 0,
    placeholderMinValue: 1,
    placeholderMaxValue: 10,
    placeholderDefaultValue: 1,
    id: 'u326273f05a68ea6890f131a5d9be4dd6',
    insertType: INSERT_TYPE.variable,
    order: 2,
  },
];

export const metadataWithCreateInsert = [
  {
    placeholderName: 'text1',
    placeholderText: 'lorem ipsum',
    placeholderMaxLength: 100,
    id: 'u6af68cece22f28a525a9299a49fba3f8',
    insertType: 'text',
    order: 0,
  },
  {
    placeholderName: 'text2',
    placeholderText: 'test',
    placeholderMaxLength: 100,
    id: 'ud0b78fcf5c72615ab0f0e9a18bb43d3e',
    insertType: 'text',
    order: 1,
  },
  {
    description: 'desc',
    id: 'u3b3145b3c363a852a67991cbd7388b81',
    insertType: 'productsDescription',
    order: 2,
  },
  {
    placeholderName: '1',
    placeholderKey: '1',
    placeholderDecimals: 0,
    placeholderMinValue: 1,
    placeholderMaxValue: 10,
    placeholderDefaultValue: 1,
    id: 'u326273f05a68ea6890f131a5d9be4dd6',
    insertType: 'variable',
    create: true,
  },
];

export const reorderedAfterCreate: Placeholder[] = [
  {
    placeholderName: 'text1',
    placeholderText: 'lorem ipsum',
    placeholderMaxLength: 100,
    id: 'u6af68cece22f28a525a9299a49fba3f8',
    insertType: INSERT_TYPE.text,
    order: 0,
  },
  {
    placeholderName: 'text2',
    placeholderText: 'test',
    placeholderMaxLength: 100,
    id: 'ud0b78fcf5c72615ab0f0e9a18bb43d3e',
    insertType: INSERT_TYPE.text,
    order: 1,
  },
  {
    placeholderName: '1',
    placeholderKey: '1',
    placeholderDecimals: 0,
    placeholderMinValue: 1,
    placeholderMaxValue: 10,
    placeholderDefaultValue: 1,
    id: 'u326273f05a68ea6890f131a5d9be4dd6',
    insertType: INSERT_TYPE.variable,
    create: true,
    order: 2,
  },
  {
    description: 'desc',
    id: 'u3b3145b3c363a852a67991cbd7388b81',
    insertType: INSERT_TYPE.productsDescription,
    order: 3,
  },
];

export const orderedAfterDelete: Placeholder[] = [
  {
    placeholderName: 'text2',
    placeholderText: 'test',
    placeholderMaxLength: 100,
    id: 'ud0b78fcf5c72615ab0f0e9a18bb43d3e',
    insertType: INSERT_TYPE.text,
    order: 0,
  },
  {
    placeholderName: '1',
    placeholderKey: '1',
    placeholderDecimals: 0,
    placeholderMinValue: 1,
    placeholderMaxValue: 10,
    placeholderDefaultValue: 1,
    id: 'u326273f05a68ea6890f131a5d9be4dd6',
    insertType: INSERT_TYPE.variable,
    order: 1,
  },
];
