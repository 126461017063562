import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  OnDestroy,
  ComponentRef,
} from '@angular/core';

import { defer, find } from 'lodash-es';
import { delay, first, skip } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  SelectedData,
  ExtendedChartMetadata,
  ExtendedEditablePlaceholderMetadata,
  ExtendedPlaceholderMetadata,
  InsertCreationParams,
  PinValues,
  TextPlaceholder,
  VariablePlaceholder,
} from '@core/model';
import { Global, GAService } from '@shared/services';
import { INSERT_TYPE } from '@core/enums';
import { InsertConfig } from '@shared/models';
import { InsertContentService } from './insert-content.service';
import { ChartComponent } from './../chart/chart.component';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { InsertContentDirective } from '@shared/pipes/insert-content.pipe';
import { InsertDropdownComponent } from './insert-dropdown/insert-dropdown.component';
import { ProductSelectorComponent } from './product-selector/product-selector.component';
import { TabInsertComponent } from './tab-insert/tab-insert.component';

@UntilDestroy()
@Component({
  selector: 'ep-insert-content',
  templateUrl: './insert-content.component.html',
  styleUrls: ['./insert-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsertContentComponent implements OnChanges, OnDestroy {
  @Input() body: string;
  @Input() inserts: ExtendedPlaceholderMetadata[];
  @Input() isCompiledPagePreview = false;

  @Output() chartIsReady = new EventEmitter();
  @Output() pinMove = new EventEmitter();
  @Output() openInsertEditingModal = new EventEmitter<ExtendedPlaceholderMetadata>();
  @Output() customButtonClick = new EventEmitter<string>();
  @Output() insertDropdownSelected = new EventEmitter<InsertConfig>();
  @Output() insertProductSelected = new EventEmitter<InsertConfig>();
  @Output() insertTabSelected = new EventEmitter<InsertConfig>();

  @ViewChild(InsertContentDirective) set innerContent(content: InsertContentDirective) {
    if (content) {
      this.innerContentRef = content;
      this.insertComponents();
    }
  }

  data: ExtendedPlaceholderMetadata[];
  innerContentRef: InsertContentDirective;

  private componentsRefs: ComponentRef<
    ChartComponent | CustomTableComponent | InsertDropdownComponent | ProductSelectorComponent | TabInsertComponent
  >[] = [];
  private clickListenerByInsertId: Record<string, (e) => void> = {};

  constructor(
    public cdr: ChangeDetectorRef,
    public insertContentService: InsertContentService,
    public global: Global,
    public gaService: GAService
  ) {}

  ngOnChanges(): void {
    this.innerContentRef && defer(this.insertComponents.bind(this));
  }

  ngOnDestroy(): void {
    this.componentsRefs.forEach(ref => {
      ref.instance instanceof ChartComponent && ref.instance?.ngOnDestroy();
      ref.destroy();
    });
  }

  private insertComponents(): void {
    this.global
      .waitImages(this.innerContentRef.viewContainerRef.element)
      .pipe(first(), untilDestroyed(this))
      .subscribe(() => {
        if (this.inserts?.length) {
          // TODO: wrong interface
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.inserts.forEach(this.createComponent.bind(this));
          !this.inserts.some(
            insert => (insert as ExtendedPlaceholderMetadata<ExtendedChartMetadata>).isChartPlaceholder
          ) && this.chartIsReadyHandler(true);
        } else if (this.inserts?.length === 0) {
          this.chartIsReadyHandler(true);
        }
      });
  }

  private createComponent(data: InsertCreationParams): void {
    if (
      data.editable &&
      data.insertType !== INSERT_TYPE.dropdown &&
      data.insertType !== INSERT_TYPE.productSelector &&
      data.insertType !== INSERT_TYPE.tab
    ) {
      this.insertContentService.createEditablePlaceholderMarkup(data);
      this.handleEditablePlaceHolder(data);
    }

    switch (data.insertType) {
      case INSERT_TYPE.chart:
        this.createChartInsert(data);
        break;
      case INSERT_TYPE.customTable:
        this.createCustomTableInsert(data);
        break;
      case INSERT_TYPE.button:
        this.createButtonInsert(data);
        break;
      case INSERT_TYPE.dropdown:
        this.createDropDownInsert(data);
        break;
      case INSERT_TYPE.productSelector:
        this.createProductSelectorInsert(data);
        break;
      case INSERT_TYPE.tab:
        this.createTabInsertComponent(data);
        break;
    }
  }

  private handleEditablePlaceHolder(data: ExtendedEditablePlaceholderMetadata): void {
    if (
      (this.global.isSharedPresentation() &&
        (data.insertType !== INSERT_TYPE.variable ||
          (data as ExtendedPlaceholderMetadata<VariablePlaceholder>).hiddenOnSharedView)) ||
      this.global.isPresentationViewRoute()
    )
      return;

    data.elementRef.classList.add('editable-placeholder');

    if (!data.listened) {
      this.clickListenerByInsertId[data.id] = e => {
        e.stopPropagation();
        const insert = find(this.inserts, { id: data.id });
        this.openInsertEditingModal.emit(insert);

        if (insert.insertType === INSERT_TYPE.variable && !this.isCompiledPagePreview) {
          this.gaService.sendInsertEvent({
            elementName: (insert as ExtendedPlaceholderMetadata<VariablePlaceholder>).placeholderName,
            eventAction: 'Variable Insert Clicked',
          });
        }

        if (insert.insertType === INSERT_TYPE.text && !this.isCompiledPagePreview) {
          this.gaService.sendInsertEvent({
            elementName: (insert as ExtendedPlaceholderMetadata<TextPlaceholder>).placeholderName,
            eventAction: 'Text Insert Clicked',
          });
        }
      };
      data.elementRef.addEventListener('click', this.clickListenerByInsertId[data.id]);
      data.listened = true;
    }
  }

  private handleClickablePlaceHolder(data: ExtendedEditablePlaceholderMetadata): void {
    if (!data.listened) {
      this.clickListenerByInsertId[data.id] = e => {
        e.stopPropagation();
        this.customButtonClick.emit(data.id);

        if (INSERT_TYPE.button === data.insertType && !this.isCompiledPagePreview) {
          this.gaService.sendInsertEvent({
            elementName: data.placeholderName,
            eventAction: 'Button Insert Clicked',
          });
        }
      };
      data.elementRef.addEventListener('click', this.clickListenerByInsertId[data.id]);
      data.listened = true;
    }
  }

  private handleDropdownChanges(data: SelectedData): void {
    if (!this.isCompiledPagePreview) {
      this.insertDropdownSelected.emit(data.value);
      this.gaService.sendInsertEvent({
        elementName: data.name,
        eventAction: 'Dropdown Insert Value Selected',
      });
    }
  }

  private handleProductSelectorChanges(data: SelectedData): void {
    if (!this.isCompiledPagePreview) {
      this.insertProductSelected.emit(data.value);
      this.gaService.sendInsertEvent({
        elementName: data.name,
        eventAction: 'Product Selector Value Selected',
      });
    }
  }

  private handleTabInsertChanges(data: SelectedData): void {
    if (!this.isCompiledPagePreview) {
      this.insertTabSelected.emit(data.value);
      this.gaService.sendInsertEvent({
        elementName: data.name,
        eventAction: 'Tab Value Selected',
      });
    }
  }

  private chartIsReadyHandler(event: PinValues[] | boolean): void {
    this.chartIsReady.emit(event);
  }

  private pinMoveHandler(event: PinValues[]): void {
    this.pinMove.emit(event);
  }

  private createProductSelectorInsert(data: InsertCreationParams): void {
    const componentRef = this.insertContentService.createProductSelectorComponent(data);

    componentRef.instance.currentValueChange
      .pipe(untilDestroyed(this), delay(200))
      .subscribe((data: SelectedData) => this.handleProductSelectorChanges(data));

    this.componentsRefs.push(componentRef);
  }

  private createDropDownInsert(data: InsertCreationParams): void {
    const isShared = this.global.isSharedPresentation();
    const isView = this.global.isPresentationViewRoute();

    if ((isShared || isView) && (data as any).hiddenOnSharedView) {
      this.insertContentService.createEditablePlaceholderMarkup(data);

      return;
    }

    const componentRef = this.insertContentService.createDropdownComponent(data);
    componentRef.instance.currentValueChange
      .pipe(untilDestroyed(this), delay(200))
      .subscribe((data: SelectedData) => this.handleDropdownChanges(data));
    this.componentsRefs.push(componentRef);
  }

  private createButtonInsert(data: InsertCreationParams): void {
    this.insertContentService.createButtonComponent(data);
    this.handleClickablePlaceHolder(data);
  }

  private createCustomTableInsert(data: InsertCreationParams): void {
    const componentRef = this.insertContentService.createCustomTableComponent(data);
    this.componentsRefs.push(componentRef);
  }

  private createChartInsert(data: InsertCreationParams): void {
    const componentRef = this.insertContentService.createChartComponent(data);

    data.rendered = !!componentRef;

    componentRef.instance.chartIsReady.pipe(untilDestroyed(this)).subscribe(this.chartIsReadyHandler.bind(this));
    componentRef.instance.pinMove.pipe(skip(1), untilDestroyed(this)).subscribe(this.pinMoveHandler.bind(this));
    componentRef.instance.pinValueChange.pipe(untilDestroyed(this)).subscribe(() => {
      if (!this.isCompiledPagePreview) {
        this.gaService.sendPinChangedEvent(data.chartName);
      }
    });

    this.componentsRefs.push(componentRef);
  }

  private createTabInsertComponent(data: InsertCreationParams): void {
    const componentRef = this.insertContentService.createTabInsertComponent(data);

    componentRef.instance.currentValueChange
      .pipe(untilDestroyed(this), delay(200))
      .subscribe((data: any) => this.handleTabInsertChanges(data));
    this.componentsRefs.push(componentRef);
  }
}
