import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
  Input,
  EventEmitter,
  Output,
  ElementRef,
} from '@angular/core';
import { Router, ActivatedRoute, Params, convertToParamMap } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Location } from '@angular/common';

import { switchMap, first, tap, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { defer, get } from 'lodash-es';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';

import { SharedSocketService, Global } from '@shared/services';
import { CUSTOM_PAGES_LIST_URL, SUCCESSFUL_UPDATE_MESSAGE } from '../../constants';
import { CustomPage, MappedPresentation, CustomPageVersion, CustomPageVersions } from '@shared/models';
import { AppState } from '../../../../reducers';
import { globalPdfGeneration } from '@ngrx-app/global.actions';
import { getPdfGeneration } from '@ngrx-app/global.selectors';
import { ChartsInjectService } from '@core/service';
import { AlertService } from '@se/common';
import {
  CustomPagePreviewService,
  CustomPageService,
  CustomPageSettingsService,
  ImportExportService,
  ModalProviderService,
} from '../../services';
import { MappedCustomPage } from '../../models';
import { InsertContentComponent } from '@shared/components/insert-content/insert-content.component';
import { Presentation, TimeDate } from '@core/model';
import { CUSTOM_PAGES_TYPES } from '@shared/constants';

@UntilDestroy()
@Component({
  selector: 'ensight-custom-page-details',
  templateUrl: './custom-page-details.component.html',
  styleUrls: ['./custom-page-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomPageDetailsComponent implements OnInit, OnDestroy {
  @Input() PDFPrint: boolean;

  @Output() visualizationReadyEvent = new EventEmitter();

  @ViewChild('templateRef') set content(content: ElementRef) {
    if (content) {
      this.handleChartPlaceholders(content);
    }
  }

  @ViewChild('innerContent') set insertContent(content: InsertContentComponent) {
    if (content) {
      this.setInserts(content);
    }
  }

  loading: boolean;
  customPage: CustomPage;
  htmlBody: SafeHtml;
  pdfGeneration$ = this.store.select(getPdfGeneration);
  customPageVersions: CustomPageVersions;
  isLoadingVersionOrCompiledPage = false;
  isNotEndPage: boolean;
  isNotDependentPage: boolean;
  selectedPresentation: MappedPresentation;
  compiledCustomPage: CustomPage;
  inserts: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private socketService: SharedSocketService,
    private cdr: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private global: Global,
    private store: Store<AppState>,
    private chartsInjectService: ChartsInjectService,
    private alertService: AlertService,
    private customPageService: CustomPageService,
    private customPageSettingService: CustomPageSettingsService,
    private modalProvider: ModalProviderService,
    private location: Location,
    private customPagePreviewService: CustomPagePreviewService,
    private importExportService: ImportExportService
  ) {
    if (get(this.router.getCurrentNavigation(), 'extras.state.successfulSave')) {
      this.showSuccessMessage();
    }
  }

  ngOnInit(): void {
    this.selectedPresentation = this.customPagePreviewService.getPresentation;
    this.watchForRoute();
  }

  ngOnDestroy(): void {
    this.store.dispatch(globalPdfGeneration({ payload: false }));
    this.socketService.destroyPDFListener();
  }

  downloadPDF(): void {
    this.store.dispatch(globalPdfGeneration({ payload: true }));
    this.socketService.sendPDFExportCustomPage(
      this.customPage._id,
      this.customPage.customFields.layout === 'landscape',
      this.selectedPresentation?.id
    );
  }

  openPageSettings(): void {
    this.router.navigate([CUSTOM_PAGES_LIST_URL, this.customPage._id, 'edit']);
  }

  leaveCustomPage(): void {
    this.router.navigate([CUSTOM_PAGES_LIST_URL]);
  }

  openPageEditText(): void {
    this.router.navigate([CUSTOM_PAGES_LIST_URL, this.customPage._id, 'edit-text']);
  }

  openPageHistory(): void {
    this.router.navigate([CUSTOM_PAGES_LIST_URL, this.customPage._id, 'history']);
  }

  exportCustomPage(): void {
    this.importExportService
      .exportCustomPage(this.customPage.uiId, this.customPage.name)
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  changeVersion(id: string): void {
    this.isLoadingVersionOrCompiledPage = true;
    this.customPageService
      .getCustomPage(id)
      .pipe(
        tap((customPage: CustomPage) => this.setCustomPage(customPage)),
        switchMap((customPage: CustomPage) =>
          this.getCompiledPageBySelectedPresentation(id, customPage, this.selectedPresentation?.id)
        ),
        switchMap(() => this.global.waitImages(this.content).pipe(first())),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.customPageService.setCurrentCustomPageAndVersions(null);
        this.location.replaceState(`${CUSTOM_PAGES_LIST_URL}/${this.customPage._id}`);
        this.visualizationReadyEvent.emit();
      });
  }

  openManageVersionModal(): void {
    const modal = this.modalProvider.openManageVersionModal(this.customPageVersions);

    modal.afterClosed
      .pipe(
        untilDestroyed(this),
        filter((value: null | CustomPageVersion[]) => !!value),
        tap(() => this.customPageService.setCurrentCustomPageAndVersions(null)),
        switchMap((versions: CustomPageVersion[]) =>
          this.customPageService.saveNewVersions(this.customPageVersions.customPageUUID, versions)
        ),
        switchMap((versions: CustomPageVersion[]) =>
          this.customPageService.saveVersions(this.customPageVersions.customPageUUID, versions)
        )
      )
      .subscribe((data: CustomPageVersions) => {
        this.customPageVersions = data;
        this.showSuccessMessage();
      });
  }

  openChoosePresentationModal(): void {
    const modal = this.modalProvider.openChoosePresentationModal(this.customPage.customFields.productsMin);

    modal.afterClosed
      .pipe(
        untilDestroyed(this),
        filter((value: null | MappedPresentation) => !!value),
        tap((presentation: MappedPresentation) => this.setPresentation(presentation)),
        switchMap((presentation: MappedPresentation) => this.getCompiledPage(presentation.id))
      )
      .subscribe((customPage: CustomPage) => this.setCompiledPage(customPage));
  }

  clearPresentation(): void {
    this.customPagePreviewService.clear();
    this.selectedPresentation = null;
    this.compiledCustomPage = null;
    this.inserts = [];
  }

  private handleChartPlaceholders(content?: ElementRef): void {
    this.chartsInjectService.setChartPlaceholdersWidth(content);
  }

  private showSuccessMessage(): void {
    this.alertService.openAlert({
      type: 'success',
      body: SUCCESSFUL_UPDATE_MESSAGE,
      autoClose: 5000,
    });
    this.cdr.markForCheck();
  }

  private watchForRoute(): void {
    this.loading = true;
    this.isLoadingVersionOrCompiledPage = true;

    this.route.params
      .pipe(
        switchMap((params: Params) =>
          this.customPageService.getCustomPageAndVersions(convertToParamMap(params).get('id'))
        ),
        tap((data: MappedCustomPage) => this.setCustomPage(data.customPage)),
        tap((data: MappedCustomPage) => (this.customPageVersions = data.customPageVersions)),
        switchMap((data: MappedCustomPage) =>
          this.getCompiledPageBySelectedPresentation(
            data.customPage._id,
            data.customPage,
            this?.selectedPresentation?.id
          )
        ),
        switchMap(() => this.createCompiledPageForPDF()),
        switchMap(() => this.global.waitImages(this.content).pipe(first())),
        untilDestroyed(this)
      )
      .subscribe(
        () => this.visualizationReadyEvent.emit(),
        () => this.leaveCustomPage()
      );
  }

  private setCustomPage(customPage: CustomPage): void {
    this.isNotEndPage = this.customPageSettingService.isNotIncludeEndPage(customPage.labels);
    this.isNotDependentPage = !customPage.labels.includes(CUSTOM_PAGES_TYPES.dependentpage);
    this.htmlBody = this.sanitizer.bypassSecurityTrustHtml(customPage.customFields.htmlBody);
    this.customPage = customPage;
    this.loading = false;
    this.cdr.markForCheck();
    this.socketService.fileName = customPage.name;
    defer(this.handleChartPlaceholders.bind(this));
  }

  private setInserts(content: InsertContentComponent): void {
    this.inserts = this.customPagePreviewService.setInserts(
      this.compiledCustomPage,
      content.innerContentRef,
      this.compiledCustomPage.inserts as any,
      this.compiledCustomPage.customFields.uiId,
      this.PDFPrint
    );

    this.cdr.detectChanges();
  }

  // this method return compiled page in case of presentation was selected and saved to local storage
  private getCompiledPageBySelectedPresentation(
    versionId: string,
    customPage: CustomPage,
    presentationId: number
  ): Observable<CustomPage> {
    if (!this.selectedPresentation || !this.isNotEndPage) {
      this.isLoadingVersionOrCompiledPage = false;
      this.cdr.markForCheck();

      return of({});
    }

    this.compiledCustomPage = null;

    return this.customPagePreviewService
      .getCompiledPageBySelectedPresentation(customPage, this.getStartDate(versionId), presentationId)
      .pipe(
        untilDestroyed(this),
        tap((customPage: CustomPage) => this.setCompiledPage(customPage))
      );
  }

  private getStartDate(versionId: string): TimeDate {
    return this.customPageVersions.versions.find(i => i.pageId === versionId).startDate;
  }

  private setPresentation(presentation: MappedPresentation): void {
    this.compiledCustomPage = null;
    this.customPagePreviewService.setPresentation = presentation;
    this.global.setPresentation = presentation;
    this.selectedPresentation = presentation;
    this.isLoadingVersionOrCompiledPage = true;
    this.cdr.markForCheck();
  }

  private getCompiledPage(presentationId: number): Observable<CustomPage> {
    return this.customPagePreviewService.getCompiledPage(
      presentationId,
      this.getStartDate(this.customPage._id),
      this.customPage
    );
  }

  private setCompiledPage(customPage: CustomPage): void {
    this.compiledCustomPage = customPage;
    this.isLoadingVersionOrCompiledPage = false;
    this.cdr.detectChanges();
  }

  private createCompiledPageForPDF(): Observable<CustomPage> {
    const id = Number(this.route.snapshot.queryParams['presentationId']);

    return id ? this.getCompiledPageForPDF(id) : of({});
  }

  // this method calls when URL includes query param(presentationId) for PDF export
  private getCompiledPageForPDF(presentationId: number): Observable<CustomPage> {
    return this.customPagePreviewService.getPresentationData(presentationId).pipe(
      tap((presentation: Presentation) => this.setPresentation(presentation)),
      switchMap((presentation: Presentation) => this.getCompiledPage(presentation.id)),
      tap((customPage: CustomPage) => this.setCompiledPage(customPage))
    );
  }
}
