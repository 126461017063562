import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import {
  PrefixesModule,
  InputModule,
  ButtonModule,
  NotificationModule,
  ModalModule,
  IconModule,
} from '@assurance/bootstrap';

import { ShortfallSettingsModalComponent } from './shortfall-settings-modal.component';
import { ShortfallSettingsModalValidatorComponent } from './shortfall-settings-modal-msg.component';
import { RetirementShortfallService } from '../../setup/retirement-shortfall/retirement-shortfall.service';
import { ThousandsSeparatorPipe } from './shortfall-settings-modal.pipe';
import {ThousandsSeparatorService} from "@shared/services";

@NgModule({
    declarations: [ShortfallSettingsModalComponent, ShortfallSettingsModalValidatorComponent, ThousandsSeparatorPipe],
    imports: [
        CommonModule,
        PrefixesModule,
        IconModule,
        InputModule,
        ButtonModule,
        NotificationModule,
        ModalModule,
        ReactiveFormsModule,
    ],
    exports: [ShortfallSettingsModalComponent],
    providers: [RetirementShortfallService, ThousandsSeparatorService]
})
export class ShortfallSettingsModalModule {}
