import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InsertContentComponent } from './insert-content.component';
import { AssurencePipesModule } from '@shared/pipes/assurence-pipes.module';
import { InsertContentService } from './insert-content.service';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { ChartModule } from './../chart/chart.module';
import { InsertDropdownComponent } from './insert-dropdown/insert-dropdown.component';
import { ThousandsSeparatorService, ImageHandlerService } from '@shared/services';
import { ProductSelectorComponent } from './product-selector/product-selector.component';
import { TabInsertComponent } from './tab-insert/tab-insert.component';

@NgModule({
  declarations: [
    InsertContentComponent,
    CustomTableComponent,
    InsertDropdownComponent,
    ProductSelectorComponent,
    TabInsertComponent,
  ],
  imports: [CommonModule, ChartModule, AssurencePipesModule],
  exports: [InsertContentComponent],
  providers: [InsertContentService, ImageHandlerService, ThousandsSeparatorService],
})
export class InsertContentModule {}
