import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, convertToParamMap, Params, Router } from '@angular/router';
import { FormControl } from '@angular/forms';

import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable, of } from 'rxjs';

import { TableBodyColumn, TableHeaderColumn, TablePaginationOptions, TableRequestParams } from '@shared/models';
import { CustomPageChanges, CustomPageHistory, MappedHistoryCustomPage } from '../../models';
import { CustomPageHistoryService, CustomPageService } from '../../services';
import {
  CUSTOM_PAGES_LIST_URL,
  DefaultHistoryOrderByRequestParam,
  getBodyColumns,
  getHeaderColumns,
  REVERT_CUSTOM_PAGE_SUCCESS_MESSAGE,
} from '../../constants';
import { PermissionKeyEnum } from '@core/enums';
import { CUSTOM_PAGES_TYPES, NoDataMessage } from '@shared/constants';
import { ConfirmModalComponent } from '../../../../components/modals/confirm-modal/confirm-modal.component';
import { TimeFormat } from '@core/constant';
import { TimeService, ModalExecutor } from '@shared/services';
import { AlertService } from '@se/common';

@UntilDestroy()
@Component({
  selector: 'ep-custom-page-history',
  templateUrl: './custom-page-history.component.html',
  styleUrls: ['./custom-page-history.component.scss'],
})
export class CustomPageHistoryComponent implements OnInit, OnDestroy {
  loading = false;
  loadingRecords = false;
  customPageInfo: MappedHistoryCustomPage;
  records: CustomPageChanges[] = [];
  paginationOptions: TablePaginationOptions;
  tableHeaderColumn: TableHeaderColumn[] = [];
  tableColumn: TableBodyColumn[] = [];
  currentOrderByParams: TableRequestParams = DefaultHistoryOrderByRequestParam;
  isMenuOpen = false;
  currentRecordIndex: number;
  Permissions = PermissionKeyEnum;
  searchControl = new FormControl();
  noDataMessage = NoDataMessage;

  constructor(
    private modalExecutor: ModalExecutor,
    private router: Router,
    private route: ActivatedRoute,
    private customPageService: CustomPageService,
    private customPageHistoryService: CustomPageHistoryService,
    private timeService: TimeService,
    private alertService: AlertService
  ) {}

  ngOnInit(): void {
    this.tableHeaderColumn = getHeaderColumns();
    this.tableColumn = getBodyColumns();
    this.loading = true;
    this.currentOrderByParams = this.customPageHistoryService.getOrderByParams();
    this.watchForRoute();
    this.watchForSearch();
  }

  ngOnDestroy(): void {
    this.customPageHistoryService.resetRequestParams();
  }

  leavePage(): void {
    this.router.navigate([CUSTOM_PAGES_LIST_URL, this.customPageInfo.id]);
  }

  setOrderByValue(value: TableRequestParams): void {
    this.loadingRecords = true;
    this.currentOrderByParams = value;
    this.customPageHistoryService.setOderByRequestParams(value);
  }

  setPaginationParams(options: TablePaginationOptions): void {
    this.loadingRecords = true;
    this.customPageHistoryService.setTableRequestParams(options);
  }

  toggleMenu(index: number): void {
    this.currentRecordIndex = index;
    this.isMenuOpen = !this.isMenuOpen;
  }

  clickOutside(): void {
    this.closeMenu();
  }

  download(index: number): void {
    this.customPageHistoryService.downloadRecord(this.records[index]);
    this.closeMenu();
  }

  exportRevision(index: number): void {
    this.customPageHistoryService.exportRevisionCustomPage(this.records[index]).pipe(untilDestroyed(this)).subscribe();
  }

  revert(index: number): void {
    const formattedDate = this.timeService.getFormattedDate(this.records[index].updated, TimeFormat.MDYYYYhmmA);

    this.modalExecutor
      .execute(ConfirmModalComponent, {
        message: `Are you sure you want to revert the custom page "${this.records[index].pageName}" to revision created on ${formattedDate} by ${this.records[index].updatedBy}? `,
      })
      .pipe(
        filter((value: boolean) => !!value),
        switchMap(() => this.customPageHistoryService.revertCustomPageHistory(this.records[index])),
        switchMap(() => this.updateHistoryList()),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.alertService.openAlert({
          type: 'success',
          body: REVERT_CUSTOM_PAGE_SUCCESS_MESSAGE,
          autoClose: 5000,
        });
        this.customPageService.setCurrentCustomPageAndVersions(null);
      });

    this.closeMenu();
  }

  closeMenu(): void {
    this.isMenuOpen = false;
  }

  private updateHistoryList(): Observable<CustomPageHistory> {
    const tableRequestPagination: TableRequestParams = this.customPageHistoryService.getPaginationParams();
    const tableRequestOrder: TableRequestParams = this.customPageHistoryService.getOrderByParams();
    const tableSearchParams: TableRequestParams = this.customPageHistoryService.getSearchParams();

    return this.customPageService
      .getCustomPageHistory(this.customPageInfo.uiId, this.customPageInfo.labels, {
        ...tableSearchParams,
        ...tableRequestPagination,
        ...tableRequestOrder,
      })
      .pipe(tap((data: CustomPageHistory) => this.setData(data)));
  }

  private watchForRoute(): void {
    combineLatest([this.route.params, this.customPageHistoryService.watchForRequestParams()])
      .pipe(
        switchMap((params: [Params, TableRequestParams]) => this.getCustomPageInfoAndTableParams(params)),
        switchMap((params: TableRequestParams) =>
          this.customPageService.getCustomPageHistory(this.customPageInfo.uiId, this.customPageInfo.labels, params)
        ),
        untilDestroyed(this)
      )
      .subscribe(
        (data: CustomPageHistory) => this.setData(data),
        () => this.leavePage()
      );
  }

  private getCustomPageInfoAndTableParams(params: [Params, TableRequestParams]): Observable<TableRequestParams> {
    const [routeParams, tableRequestParams] = params;

    return this.customPageInfo
      ? of(tableRequestParams)
      : this.customPageService.getCustomPageForHistory(convertToParamMap(routeParams).get('id')).pipe(
          tap((data: MappedHistoryCustomPage) => (this.customPageInfo = data)),
          tap(() => {
            // we hide change log for dependent page
            if (this.customPageInfo.labels.includes(CUSTOM_PAGES_TYPES.dependentpage)) {
              this.leavePage();
            }
          }),
          map(() => tableRequestParams)
        );
  }

  private setData(data: CustomPageHistory): void {
    this.records = data.data;
    this.paginationOptions = {
      currentPage: data.currentPage,
      totalElements: data.count,
      itemsPerPage: 10,
    };
    this.loading = false;
    this.loadingRecords = false;
  }

  private watchForSearch(): void {
    this.searchControl.valueChanges
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        tap((value: string) => this.customPageHistoryService.setSearchRequestParams(value)),
        debounceTime(500)
      )
      .subscribe(() => (this.loadingRecords = true));
  }
}
