import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ButtonModule, ModalModule, InputModule, LoaderModule } from '@assurance/bootstrap';

import { AssurencePipesModule } from '@shared/pipes/assurence-pipes.module';
import { TinyEditorModule } from '@shared/components';
import { CoverSheetModalComponent } from './cover-sheet-modal.component';
import { PlansGridModule } from '../../shared/plans-grid/plans-grid.module';
// import { StyleEditorModule } from '../../../style-editor/style.editor.module';
import { PdfFooterTextModule } from '../../shared/pdf-footer-text/pdf-footer-text.module';
import { CoverSheetModalService } from './cover-sheet-modal.service';
import { CoverSheetTemplatesModule } from '../../shared/cover-sheet-templates/cover-sheet-templates.module';
import { TemplateSrcPipe } from './cover-sheet-modal.pipe';
import { CoverSheetPlaceholderComponent } from './cover-sheet-placeholder/cover-sheet-placeholder.component';
import { CoverSheetInputChipsComponent } from './cover-sheet-input-chips/cover-sheet-input-chips.component';

@NgModule({
  declarations: [
    CoverSheetModalComponent,
    TemplateSrcPipe,
    CoverSheetPlaceholderComponent,
    CoverSheetInputChipsComponent,
  ],
  exports: [CoverSheetModalComponent],
  imports: [
    CoverSheetTemplatesModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    ModalModule,
    InputModule,
    LoaderModule,
    RouterModule,
    AssurencePipesModule,
    PlansGridModule,
    // StyleEditorModule,
    TooltipModule.forRoot(),
    PdfFooterTextModule,
    TinyEditorModule,
  ],
  providers: [CoverSheetModalService],
})
export class CoverLetterModalModule {}
