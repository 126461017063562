export const baseUrl = '/api/';
export const contentService = `/content-service/api`;
export const baseSharedUrl = `${baseUrl}share/`;
export const login = `${baseUrl}users/login`;
export const logout = `${baseUrl}users/logout`;
export const usersFullName = `${baseUrl}users/full-name`;
export const checkAuth = `${baseUrl}users/check/auth`;
export const agency = `${baseUrl}agencies`;
export const agenciesNames = 'agencies/names';
export const presentation = `${baseUrl}presentation`;
export const presentationCount = `${presentation}/count`;
export const dropTicket = `${presentation}/drop-ticket`;
export const dropTicketPlans = `${presentation}/drop-ticket/plans`;
export const presentationQty = `${presentation}/qty`;
export const presentationsPlansCount = `${presentation}/plans/qty`;
export const agencyChildren = 'children';
export const carrierPlans = 'carrier-plans';
export const carrierPlanData = `${baseUrl}carrier-plan-data`;
export const carrierPlansConfig = `${baseUrl}config/plan`;
export const carrierPlansConfigData = `${baseUrl}config/data`;
export const carrierPlansConfigMeta = `${baseUrl}config/meta`;
export const dataSourcesConfig = `${baseUrl}config/data-sources`;
export const carrierPlansFullConfigData = `${carrierPlansConfigData}/full`;
export const sharedCarrierPlansConfig = `${baseSharedUrl}config/plan`;
export const plansOrder = 'carrier-plans/order';
export const setupConfigs = 'configs';
export const pageConfig = 'page-config';
export const dependentPagesConfigs = 'dependent-pages/configs';
export const clone = 'clone';
export const exportToPDF = `${baseUrl}pdf`;
export const customCall = `${baseUrl}custom-call`;
export const sharedCustomCall = `${baseSharedUrl}custom-call`;
export const coverLetter = 'cover-letter';
export const events = 'events';
export const presentationSettings = 'config';
export const csv = 'csv';
export const clientName = 'client-name';
export const presentationName = 'presentation-name';
export const presentationType = 'presentation-type';
export const metadata = 'metadata';
export const compile = 'compile';
export const sharedAnalytics = `${baseSharedUrl}analytics`;
export const sharedCurrentTheme = `${baseSharedUrl}theme`;
export const sharedAgencyRules = `${baseSharedUrl}agency/rules`;
export const sharedCreateEvent = `${baseSharedUrl}event`;
export const sharedCarrierPlanData = `${baseSharedUrl}carrier-plans`;
export const sharedDependentPermissions = `${baseSharedUrl}group/dependent-permissions`;
export const sharedPresentationConfigs = `${baseSharedUrl}configs`;
export const sharedMetadata = `${baseSharedUrl}metadata/list`;
export const distribute = 'distribute';
export const sharedPresentation = `${baseSharedUrl}presentation`;
export const sharedPresentationCoverLetter = `${baseSharedUrl}cover-letter`;
export const filter = 'presentation/filter';
export const sharedContentServiceContent = `${baseSharedUrl}content`;
export const sharedDependentPagesConfig = `${baseSharedUrl}dependent-pages/configs`;
export const contentServiceContent = `${contentService}/content`;
export const contentServiceContentCustomPages = `${contentService}/content/custom-pages`;
export const contentServiceContentCustomPagesByLabels = `${contentService}/content/custom-pages/by-labels`;
export const contentServiceContentCustomPagesByUuId = `${contentService}/content/custom-pages/by-uuid`;
export const customPagesContent = `${contentServiceContent}/custom-pages`;
export const customPagesContentV2 = `${contentServiceContent}/v2/custom-pages`;
export const copyCustomPage = `${contentServiceContent}/copy`;
export const salesConceptData = `${contentServiceContentCustomPages}/available-sales-concepts-for-group`;
export const customPage = `${baseUrl}custom-page`;
export const customImage = `${contentServiceContent}/assets/custom-image/`;
export const copyCustomImage = `${contentServiceContent}/assets/custom-image-copy`;
export const downloadPDF = `${baseUrl}download-pdf`;
export const downloadIncomingData = `${baseUrl}incoming-data`;
export const defaultPagesList = `${baseUrl}configs/default`;
export const customPagesList = `${baseUrl}configs/custom`;
export const carriersList = `${baseUrl}custom-page/carriers`;
export const support = `${baseUrl}info/support`;
export const transactionCaseData = `${baseUrl}case/transaction/data`;
export const eiqCase = '/case';
export const pagesInserts = '/pages/inserts';
export const downloadInforceCSV = `/inforce/csv`;
export const PolicyInForceRecords = '/inforce/policy-records';
export const versions = `${contentService}/version`;
export const downloadMetricsCSV = `${contentServiceContent}/generate-csv-from-custom-metrics`;
export const exportCustomPage = `${contentService}/export`;
export const importCustomPage = `${contentService}/import`;
export const customPageHistory = `${contentService}/content-history`;
export const navbarCustomPages = `${customPagesContent}/navbar-custom-pages`;
export const sharedNavbarCustomPages = `${baseSharedUrl}content/custom-pages/navbar-custom-pages`;
