import { Injectable } from '@angular/core';

import {
  AdditionalNavBarPages,
  CustomFiled,
  CustomPageIds,
  CustomPageValues,
  NavbarCustomPage,
  NavbarCustomPagesData,
  NavbarCustomPagesOptions,
  PageConfig,
} from '@shared/models';
import { isDefined } from '@core/utils';

@Injectable()
export class NavbarPagesAdapterService {
  getSalesConceptsInfo(
    salesConceptsUiIds: string[],
    dependentPagesConfig,
    withInternalId?: boolean
  ): NavbarCustomPagesOptions[] {
    return salesConceptsUiIds.map((uiId: string) => {
      const addedDate = dependentPagesConfig.find(item => item.uiId === uiId)?.addedDate;
      const info = this.getSalesConceptIds(uiId);
      const mappedItem = addedDate ? { addedDate, uiId: info.uiId } : { uiId: info.uiId };

      return withInternalId ? { ...mappedItem, internalId: info.internalId } : mappedItem;
    });
  }

  mapInternalIdToPage(
    data: NavbarCustomPage[],
    configs: PageConfig[],
    dataWithInternalId: NavbarCustomPagesOptions[]
  ): NavbarCustomPage[] {
    return dataWithInternalId.map((item: NavbarCustomPagesOptions) => {
      const foundConfig = configs.find((config: PageConfig) => {
        const info = this.getSalesConceptIds(config.config.uiId);

        return item.uiId === info.uiId && info.internalId === item.internalId;
      });

      const foundPage = data.find((page: NavbarCustomPage) => page.uiId === item.uiId);

      return foundConfig && foundPage ? { ...foundPage, config: { uiId: foundConfig?.config.uiId } } : foundPage;
    });
  }

  separatePages(data: AdditionalNavBarPages): NavbarCustomPagesData {
    const salesConcepts: CustomPageValues[] = [];
    const dependentPages: CustomPageValues[] = [];

    data.pages.forEach((item: NavbarCustomPage) => {
      const concept = this.adaptNavbarConceptPages(item);

      if (!concept.config) {
        return;
      }

      const dpPages = this.adaptNavbarDependentPages(concept.dependentPages, concept.config.uiId);

      salesConcepts.push(concept);
      dependentPages.push(...dpPages);
    });

    return {
      fullData: data.fullData,
      salesConcepts,
      dependentPages,
    };
  }

  adaptNavbarEndPages(endPages: NavbarCustomPage[]): Partial<Omit<CustomPageValues, 'dependentPages'>>[] {
    return endPages
      .map((page: NavbarCustomPage) => {
        return {
          ...page,
          config: { uiId: page.uiId },
          label: page.name,
          isEndPage: true,
          carrierUiIds: page.customFields.carrierUiIds,
          hideOnPDF: page.customFields.hideOnPDF,
          layout: page.customFields.layout,
          disabled: page.customFields.disabled,
          order: page.customFields.order,
          orderRank: page.customFields.orderRank,
        };
      })
      .sort((a, b) => a.orderRank - b.orderRank);
  }

  getSalesConceptsUiIds(data: NavbarCustomPage[]): string[] {
    return data
      .filter(item => !!item)
      .filter(
        (item: NavbarCustomPage) =>
          item.dependentPages && this.getDependentPagesWithShowHideFormula(item.dependentPages).length > 0
      )
      .map((item: NavbarCustomPage) => item.config.uiId);
  }

  getFilteredDependentPages(pages: NavbarCustomPage[], data: CustomPageValues[]): NavbarCustomPage[] {
    return pages.map(item => {
      const found = data.find(d => d.uiId === item.uiId);
      const mappedFound = found ? found.customFields.dependentPages.map(r => r.uiId) : [];

      return {
        ...item,
        dependentPages: found ? item.dependentPages.filter(dp => mappedFound.includes(dp.uiId)) : item.dependentPages,
      };
    });
  }

  private adaptNavbarConceptPages(page: NavbarCustomPage): CustomPageValues {
    const concept = { ...page };
    concept.customFields = concept.customFields || ({} as CustomFiled);

    concept.productsRange = [concept.customFields.productsMin, concept.customFields.productsMax] as [number, number];
    concept.label = concept.label || concept.name;
    concept.layout = concept.customFields.layout;
    concept.dependentPagesLocked = concept.customFields.dependentPagesLocked;
    concept.isPageLockedAndEnabled = concept.customFields.isPageLockedAndEnabled;
    concept.isEligibleForDataSources = concept.customFields.isEligibleForDataSources;
    concept.eligibleDataSources = concept.customFields.eligibleDataSources;
    concept.dependentPages = concept.dependentPages.map(item => {
      const found = concept.customFields.dependentPages
        ? concept.customFields.dependentPages.find(p => p.uiId === item.uiId)
        : undefined;

      return {
        ...item,
        customFields: {
          ...item.customFields,
          order: found.order,
          label: page.label,
        },
        order: found.order,
        layout: page.customFields.layout,
        disabled: page.customFields.disabled,
      };
    });

    return concept as CustomPageValues;
  }
  private adaptNavbarDependentPages(
    dependentPages: CustomPageValues[],
    parentUiId: string
  ): Partial<Omit<CustomPageValues, 'dependentPages'>>[] {
    return dependentPages.map((page: CustomPageValues) => {
      return {
        ...page,
        parentUiId,
        isDependentPage: true,
        label: page.label, // || page.name,
        config: { uiId: page.uiId },
        layout: page.customFields.layout,
        disabled: page.customFields.disabled,
        order: page.customFields.order,
        isPageLockedAndEnabled: page.customFields.isPageLockedAndEnabled,
      };
    });
  }

  private getSalesConceptIds(id: string): CustomPageIds {
    const [uiId, internalId] = id ? id.split('.') : [];

    return { uiId, internalId: Number(internalId) || null };
  }

  private getDependentPagesWithShowHideFormula(dependentPages: NavbarCustomPage[]): NavbarCustomPage[] {
    return dependentPages.filter((item: NavbarCustomPage) => isDefined(item.customFields.showHideFormula));
  }
}
