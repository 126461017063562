export const CUSTOM_PAGES_TYPES = {
  endpage: 'endpage',
  dependentpage: 'dependentpage',
  salesconcept: 'salesconcept',
};

export const END_PAGES_QUERY_PARAMS = {
  types: ['endpage'],
};

export const PAGE_TYPES: Record<string, string> = {
  endpage: 'End Page',
  dependentpage: 'Dependent Page',
  salesconcept: 'Sales Concept',
};
