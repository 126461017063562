import { Router } from '@angular/router';
import {
  ElementRef,
  Input,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  Directive,
  ViewRef,
  OnDestroy,
} from '@angular/core';

import { combineLatest, Subject } from 'rxjs';
import { get } from 'lodash-es';
import { select, Store } from '@ngrx/store';
import { skip, takeUntil } from 'rxjs/operators';

import { AppState } from '../../../reducers';
import { Global, GAService } from '@shared/services';
import { getPdfGeneration } from '@ngrx-app/global.selectors';
import { mouseUpSuccess } from '@shared/components/chart/chart.actions';

// TODO: need to change. Components or something else stuff should be in this or core folder
import * as setupConstant from '../../../components/presentation/setup/setup.constant';
import { GRAPH_VALUES, METRICS_TO_HIDE } from '@shared/constants';
import { SetupService } from '../../../components/presentation/setup/setup.service';
import { PdfExportService } from '@core/service';
import { VisualizationReadyEvent, GlobalConfig, PageConfig, AgeLimitConfig, PlanMetric } from '@shared/models';
import * as VisualizationActions from '../../../components/presentation/redux/configs/visualization.actions';
import {
  getGlobalMaxAgeDisplay,
  getGlobalMaxYDisplay,
  getGlobalMinAgeDisplay,
  getGlobalMinYDisplay,
  getGraphValue,
  getPresentationGlobalConfig,
  getPresentationPageConfig,
} from '../../../components/presentation/redux/configs/selectors';
import { CareerPlan, DisclosureOptions, Metric, Presentation, DragModelData, XAxisSourceType } from '@core/model';

@Directive()
export abstract class Visualization implements OnDestroy {
  //TODO: need to remove. How abstraction can have @input and @output (this relative to view). THIS IS ABSTRACTION! How come!!!
  @Input() pdfConfig: any = null;
  @Input() configKey: string;
  @Input() PDFPrint: boolean;

  @Output() visualizationReadyEvent = new EventEmitter<VisualizationReadyEvent>();

  pdfGeneration$ = this.store.pipe(select(getPdfGeneration));
  getGraphValue$ = this.store.pipe(select(getGraphValue));
  protected pageID: string;
  config: [GlobalConfig, PageConfig];
  planMetrics: DragModelData[] = [];
  configMinMax: AgeLimitConfig;
  disclosureOptions: DisclosureOptions;
  activePlans: CareerPlan[];
  plans: CareerPlan[];
  protected metricsKeys: string[];
  hideChartFlag = false;
  graphValue: XAxisSourceType;
  presentation: Presentation;
  agencyRules: Record<string, string>;
  unsubscribe$ = new Subject<void>();

  private _selectedMetric: Metric;

  protected abstract container: ElementRef;

  get hideChart() {
    return this.hideChartFlag;
  }

  set hideChart(value: boolean) {
    if (!this.PDFPrint) {
      this.hideChartFlag = value;
      (this.cdRef as ViewRef).destroyed || this.cdRef.detectChanges();
    }
  }

  get selectedMetric() {
    return this._selectedMetric;
  }

  set selectedMetric(metric) {
    this._selectedMetric = metric;
  }

  constructor(
    public global: Global,
    public router: Router,
    public setupService: SetupService,
    public presentationViewService: PdfExportService,
    public store: Store<AppState>,
    public cdRef: ChangeDetectorRef,
    public gaService: GAService
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isPresentationViewRoute(): boolean {
    return this.router.url.search(setupConstant.PRESENTATION_VIEW_ROUTER_REGEXP) !== -1;
  }

  getConfigs() {
    return combineLatest([
      this.store.select(getPresentationGlobalConfig),
      this.store.select(getPresentationPageConfig(this.configKey, this.pdfConfig)),
    ]);
  }

  getFilteredMetrics(configPlanMetrics: DragModelData[] | PlanMetric[]): DragModelData[] {
    if (this.plans.length) {
      return configPlanMetrics.filter(
        metric =>
          ![...METRICS_TO_HIDE].includes(metric.key) &&
          this.plans.some(plan => plan.configjson.data[metric.key]) &&
          this.isMetricTypeValid(metric.key)
      );
    }

    return [];
  }

  setMinMaxLimitation(value: { min?: number; max?: number }): void {
    if (value.hasOwnProperty('max') || value.hasOwnProperty('min')) {
      this.hideChart = true;
    }

    if (value.hasOwnProperty('max')) {
      this.store.dispatch(
        VisualizationActions.updateChartConfigXMax({
          uiId: this.pageID,
          xMax: value.max,
        })
      );
    }

    if (value.hasOwnProperty('min')) {
      this.store.dispatch(
        VisualizationActions.updateChartConfigXMin({
          uiId: this.pageID,
          xMin: value.min,
        })
      );
    }

    this.handleLimitation();
  }

  handleMouseUpEvent(): void {
    this.store.dispatch(mouseUpSuccess({ mouseUp: true }));
  }

  setMinMaxConfigs(force = false): void {
    this.configMinMax = {
      min: get(this.config[1], 'config.chartConfig.xMin') as number,
      max: get(this.config[1], 'config.chartConfig.xMax') as number,
      label: this.isEoyAge() ? GRAPH_VALUES.eoy_age : GRAPH_VALUES.policy_year,
      validRange: {
        min: this.setupService.findPlanMetricsMin(
          this.activePlans,
          this.setupService.getXAxisSource(this.config[1], this.graphValue)
        ),
        max: this.setupService.findPlanMetricsMax(
          this.activePlans,
          this.setupService.getXAxisSource(this.config[1], this.graphValue)
        ),
      },
      level: 'on graph',
      force,
    };
    this.disclosureOptions = {
      minAge: this.setupService.getMinAgeValue(this.config[1]),
      maxAge: this.setupService.getMaxAgeValue(this.config[1], this.agencyRules.maxAge, this.pageID, this.graphValue),
      graphLabel: this.isEoyAge() ? 'age' : 'years',
      isIrrChart: this.setupService.isIRRPlan(this.selectedMetric?.key),
      irrSettingsValue: this.global.presentationSettings.irrSettings,
      selectedMetricId: this.selectedMetric?.key,
    };
  }

  isEoyAge(): boolean {
    return this.setupService.getXAxisSource(this.config[1], this.graphValue) === 'eoy_age';
  }

  setDisclosures(): void {
    this.setupService.setDisclosures(this.agencyRules, this.presentation);
  }

  protected abstract handleLimitation(): void;

  protected attachVisualizationListeners(): void {
    combineLatest([
      this.store.select(getGlobalMinAgeDisplay),
      this.store.select(getGlobalMaxAgeDisplay),
      this.store.select(getGlobalMaxYDisplay),
      this.store.select(getGlobalMinYDisplay),
      this.store.select(getGraphValue),
    ])
      .pipe(skip(1), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.hideChart = true;
        this.handleLimitation();
        this.hideChart = false;
      });
  }

  private isMetricTypeValid(metricKey: string): boolean {
    return !!this.global.getPlansConfig.data.find(
      config => config.db === metricKey && ['percent', 'currency', 'string'].some(type => type === config.type)
      // config => config.db === metricKey && (config.type === 'percent' || config.type === 'currency' || config.type === 'string')
    );
  }
}
